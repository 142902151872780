.OfferDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 140px;

  .buttons {
    display: flex;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .OfferDetails {
    width: 100%;
    padding-top: 116px;
    .buttons {
      width: 100%;
      flex-direction: column;
    }
  }
}
