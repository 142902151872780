.CookiesPolicy {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
  height: 80px;
  padding-top: 20px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: var(--white-color);

  p {
    margin: 0;
    margin-top: 8px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  a {
    color: #ff2d55;
    text-decoration: none;
  }
}

@media (max-width: 1024px) {
  .CookiesPolicy {
    align-items: center;
    gap: 18px;
    height: 111px;
    padding-top: 0;
    padding-left: 14px;
    padding-right: 16px;
    p {
      margin-top: 0;
      height: auto;
    }
  }
}
