.Selfie {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 443px;

  p {
    margin: 0;
    font-family: 'National 2 Condensed';
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: var(--black-color);
  }

  img {
    margin-top: 11px;
    margin-bottom: 13px;
  }
}
