.Dropdown {
  position: relative;

  &.withShadow {
    .selector {
      box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
    }

    .options {
      box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
    }
  }

  .label {
    margin-bottom: 4px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: var(--black-color);
  }

  .selector {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='%23242833' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 26px;
    background-color: var(--white-color);
    border: 1px solid #f2f4f8;
    border-radius: 15px;
    cursor: pointer;

    &.open {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L7 2L1 8' stroke='%23242833' stroke-width='2'/%3E%3C/svg%3E%0A");
      border-bottom: none;
      border-radius: 15px 15px 0 0;
    }

    .placeholder {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: var(--gray-color);
    }

    .value {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      color: var(--black-color);
    }
  }

  .options {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 100%;
    margin: 0;
    padding-top: 26px;
    padding-left: 20px;
    padding-right: 26px;
    padding-bottom: 20px;
    list-style: none;
    background-color: var(--white-color);
    border: 1px solid #f2f4f8;
    border-top: none;
    border-radius: 0 0 15px 15px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% - 46px);
      height: 1px;
      background-color: var(--gray-color);
    }

    li {
      height: 40px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.69708' y='0.69708' width='22.6058' height='22.6058' rx='11.3029' fill='white'/%3E%3Cpath d='M17.5766 7.81641L9.90874 15.4843L6.42334 11.9989' stroke='white' stroke-width='1.50002' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.69708' y='0.69708' width='22.6058' height='22.6058' rx='11.3029' stroke='%23D1D1D1' stroke-width='1.39416'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center right;
      cursor: pointer;

      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.69708' y='0.69708' width='22.6058' height='22.6058' rx='11.3029' fill='%23D52D34'/%3E%3Cpath d='M17.5766 7.81641L9.90874 15.4843L6.42334 11.9989' stroke='white' stroke-width='1.50002' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.69708' y='0.69708' width='22.6058' height='22.6058' rx='11.3029' stroke='%23D52D34' stroke-width='1.39416'/%3E%3C/svg%3E ");
      }
    }
  }
}

@media (max-width: 1024px) {
  .Dropdown {
    .options {
      position: relative;
      gap: 16px;
    }
  }
}
