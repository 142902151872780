.JobsSearch {
  padding-left: 116px;
  padding-right: 116px;

  &.noLocation {
    .container {
      .sortOrderDropdownContainer {
        &::after {
          left: 18px;
        }
      }
    }
  }

  .container {
    display: flex !important;
    align-items: center;
    height: 64px;
    padding-left: 8px;
    background-color: #f2f4f8;
    border-radius: 15px;

    .searchContainer {
      flex: 1;
    }

    .citySelectorContainer {
      position: relative;
      height: 100%;
    }

    .sortOrderDropdownContainer {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        width: 2px;
        height: 35px;
        background-color: var(--black-color);
      }
    }

    .citySelector {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 64px;
      padding-right: 54px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      text-align: right;
      color: var(--brand-color);
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0853 21.9405C11.9137 21.0148 19.3682 16.8041 19.3682 9.68411C19.3682 4.33572 15.0325 0 9.68411 0C4.33572 0 0 4.33572 0 9.68411C0 16.8041 7.45448 21.0148 9.28289 21.9405C9.53703 22.0692 9.8312 22.0692 10.0853 21.9405ZM9.68411 13.8344C11.9763 13.8344 13.8344 11.9763 13.8344 9.68411C13.8344 7.39195 11.9763 5.53378 9.68411 5.53378C7.39195 5.53378 5.53378 7.39195 5.53378 9.68411C5.53378 11.9763 7.39195 13.8344 9.68411 13.8344Z' fill='%23D52D34'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: left 35px center;
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .JobsSearch {
    padding-left: 0;
    padding-right: 0;

    &.noLocation {
      .container {
        .sortOrderDropdownContainer {
          &::after {
            display: none;
          }
        }
      }
    }

    .container {
      .searchContainer {
        input {
          width: 100%;
        }
      }

      .citySelector {
        padding-left: 32px;
        padding-right: 22px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        background-position: left 11px center;
        background-size: 12px 14px;
      }
    }
  }
}
