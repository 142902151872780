.Answer {
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;

  &.active {
    .answer {
      color: var(--white-color);
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.75 12.0019L10.58 14.8319L16.25 9.17188' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-color: #0acf83;
    }
  }

  .index {
    width: 8px;
    margin-right: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .answer {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    padding-left: 20px;
    padding-right: 60px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: black;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-color: #f2f4f8;
    border-radius: 8px;
  }
}

@media (max-width: 1024px) {
  .Answer {
    .answer {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
