.button {
  position: absolute;
  padding: 0;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  background-color: transparent;
  border: none;

  &.backButton {
    top: 0;
    left: 0;
  }

  &.closeButton {
    top: 0;
    right: 0;
  }
}

.qrButton {
  position: absolute;
  right: 34px;
  top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 180px;
  height: 45px;
  font-family: 'National 2 Condensed';
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #d52d34;
  background-color: white;
  border-radius: 15px;
  border: none;
}
