.Messages {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 83px;
  padding-left: 33px;
  padding-right: 70px;
  padding-bottom: 58px;

  .header {
    width: 100%;
    margin-bottom: 40px;

    .backButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  .messagesContainer {
    max-height: calc(100vh - 443px);
    padding-top: 18px;
    padding-bottom: 30px;
    padding-left: 29px;
    padding-right: 31px;
    background-color: #f2f4f8;
    border-radius: 20px;
    overflow-y: auto;
    scrollbar-width: thin;

    .messagesList {
      margin: 0;
      padding: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
}

@media (max-width: 1024px) {
  .Messages {
    padding-top: 61px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 21px;
    .header {
      margin-bottom: 31px;
    }

    .messagesContainer {
      // max-height: calc(100svh - 580px);
      max-height: 614px;
      padding-top: 0;
      padding-left: 21px;
      padding-right: 20px;
      .messagesList {
      }
    }

    .buttons {
      flex-direction: column;
      gap: 30px;
      margin-top: 25px;
    }
  }
}
