@import '../../../../mixins';

.Tabs {
  position: absolute;
  top: 198px;
  left: 0;
  display: flex;
  align-items: center;
  gap: 100px;

  @include onMobile {
    top: 95px;
    gap: 54px;
  }

  &.animationHasPlayed {
    transition: left 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);

    .tabButton {
      transition: all 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
    }
  }

  &.visible {
    .tabButton {
      opacity: 0.4;

      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .tabButton {
    font-family: 'National 2 Condensed';
    font-size: 45px;
    font-weight: 700;
    line-height: 51px;
    text-align: center;
    text-transform: uppercase;
    color: var(--white-color);
    background-color: transparent;
    opacity: 0;
    border: none;
    white-space: pre-line;
    transform: scale(0.8);
    transition: opacity 1.2s cubic-bezier(0.25, 0.1, 0.25, 1);

    @include onMobile {
      font-size: 15px;
      line-height: 19px;
    }
  }
}
