.ScheduleDetails {
  width: 627px;

  h3 {
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    color: var(--gray-color);
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .row {
      display: flex;
      gap: 27px;

      .col {
        flex: 1;
      }
    }
  }
}

@media (max-width: 1024px) {
  .ScheduleDetails {
    width: 100%;

    h3 {
      margin-bottom: 10px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 41px;
      color: var(--gray-color);
    }

    .info {
      gap: 20px;
      .row {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
