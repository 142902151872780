.Button {
  width: 100%;
  height: 64px;
  padding-left: 33px;
  padding-right: 33px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: var(--white-color);
  background-color: var(--brand-color);
  border-radius: 16px;
  border: none;
  cursor: pointer;

  &.jobApplicationInfo {
    font-weight: 500;
    font-size: 24px;
  }

  &.likeDisabled {
    background-color: var(--gray-color);
  }

  &:disabled {
    background-color: var(--gray-color);
    cursor: auto;
  }

  &:hover {
    &:not(:disabled) {
      background-color: black;
    }
  }
}

.ButtonBlack {
  width: 100%;
  height: 64px;
  padding-left: 33px;
  padding-right: 33px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: var(--white-color);
  background-color: #000;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: var(--gray-color);
    cursor: auto;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--brand-color);
    }
  }
}
