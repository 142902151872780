.StartDateOrScheduled {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 60px;
  padding-bottom: 48px;
}

@media (max-width: 1024px) {
  .StartDateOrScheduled {
    padding-top: 69px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
