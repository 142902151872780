.DocUploader {
  width: 100%;
  input {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    h1 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-size: 18px;
      line-height: 24px;
      color: var(--black-color);
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }

    span {
      font-family: 'National 2 Condensed';
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-color);
    }
  }

  .uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 127px;
    background-color: var(--white-color);
    border: 1.5px dashed var(--gray-color);
    border-radius: 16px;
    cursor: pointer;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: var(--gray-color);

      .browse {
        color: #cb2a33;
      }

      .fileType {
        color: black;

        &.hasFile {
          position: relative;
          display: flex;
          align-items: center;
          height: 44px;
          padding-left: 29px;
          padding-right: 29px;
          background-color: var(--gray-color);
          border-radius: 10px;
          font-family: 'National 2 Condensed';
          font-size: 18px;
          line-height: 24px;
          color: var(--white-color);
          white-space: nowrap;
          user-select: none;

          i {
            position: absolute;
            top: -15px;
            right: -15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            background-color: #ff2d55;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
