@import '../../../mixins';

.TimeLine {
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-color);
    overflow: hidden;

    @include onMobile {
      height: 683px;
      padding-top: 250px;
      padding-left: 160px;
      overflow-x: auto;
      // Hide scrollbar
      scrollbar-width: none;
    }

    .title {
      margin-bottom: 470px;
      font-family: 'National 2 Condensed';
      font-size: 80px;
      font-weight: 700;
      line-height: 91px;
      text-align: center;
      color: var(--brand-color);
      text-transform: uppercase;

      @include onMobile {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 222;
        font-size: 30px;
        line-height: 34px;
      }

      .animation {
        display: inline-block;
        transform: translateY(120%);
        opacity: 0;

        &.inView {
          animation: slideUp 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
        }
      }
    }

    .timeline {
      position: relative;
      width: calc(100% - 171px);
      height: 4px;
      margin-left: 171px;
      margin-bottom: 619px;
      //  background-color: black;

      @include onMobile {
        // width: 900px;
        // min-width: 900px;
        width: 100%;
        max-width: 600px;
        height: 2px;
        margin-bottom: 565px;
        margin-left: 0;

        &::before {
          content: '';
          position: absolute;
          z-index: 0;
          top: -1px;
          left: 10px;
          width: 960px;
          height: 2px;
          background-color: black;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform 5s linear;
        }

        &.visible {
          &::before {
            transform: scaleX(1);
          }
        }
      }

      .progress {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 10px;
        background-color: black;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 5s linear;

        &.visible {
          transform: scaleX(1);
        }
      }
    }

    .button {
      width: 425px;
      height: 96px;
      font-family: 'National 2 Condensed';
      font-size: 50px;
      font-weight: 700;
      line-height: 96px;
      text-align: center;
      color: var(--white-color);
      text-transform: uppercase;
      background-color: var(--brand-color);
      border-radius: 56px;
      border: none;

      @include onMobile {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 258px;
        height: 57px;
        font-size: 35px;
        line-height: 57px;
        border-radius: 30px;
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(120%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
