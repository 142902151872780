.Carousel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  // min-width: 444px;
  height: 208px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 11px;
  background-image: url(../../../assets/images/dashboard/carousel-background.jpg);
  background-size: cover;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .filterButtonContainer {
    width: 202px;
    max-width: 202px;
  }

  .switchButtons {
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      width: 6px;
      height: 6px;
      padding: 0;
      font-size: 0;
      border: none;
      border-radius: 50%;

      &.active {
        background-color: var(--white-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .Carousel {
    padding-bottom: 19px;
    .filterButtonContainer {
      width: 164px;
      height: 46px;

      & > div {
        height: 46px;
        border-radius: 8px;
      }
    }

    .switchButtons {
      display: none;
    }
  }
}
