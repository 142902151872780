.JobsControls {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  .filters {
    display: flex;
    gap: 20px;

    .filterButtonContainer {
      width: 202px;
    }
  }
}

@media (max-width: 1024px) {
  .JobsControls {
    flex-direction: column;
    gap: 17px;
    width: 100wv;

    .filters {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .filterButtonContainer {
        width: 150px;
      }
    }

    .searchContainer {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
