.TimeDropdown {
  display: flex;
  justify-content: space-between;

  .label {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
  }

  .selectors {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
