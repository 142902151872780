.button {
  position: absolute;
  left: calc(50% - 35px);
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid var(--white-color);
}

.recordButton {
  width: 70px;
  height: 70px;
  padding: 0;
}

.stopButton {
  width: 70px;
  height: 70px;
}

.pauseButton {
  padding: 0;
  border: none;
}

.resumeButton {
  padding: 0;
  border: none;
}

.smallStopButton {
  padding: 0;
  border: none;
}

.playPreviewButton,
.pausePreviewButton {
  border: none !important;
  padding: 0;
}
