.VideoProgress {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);

  .progressBar {
    width: 280px;
    height: 4px;
    background-color: var(--secondary-color);
    border-radius: 20px;
  }

  .handle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    width: 13px;
    height: 13px;
    background-color: var(--white-color);
    box-shadow: 2px 2px 8px rgba(114, 102, 102, 0.25);
    border-radius: 50%;
    cursor: pointer;
  }
}
