.DropOff {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  overflow-y: auto;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 35px;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 252px;

    .navHeaderContainer {
      width: 100%;

      .innerContainer {
        position: relative;
        padding-top: 69px;
      }
    }

    .row {
      display: flex;
      gap: 20px;
      width: 100%;
      margin-bottom: 171px;
      .col {
        flex: 1;
        .colHeading {
          min-height: 144px;
          margin: 0;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 46px;
          line-height: 55px;
          color: var(--black-color);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .DropOff {
    padding-top: 32px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 49px;
    .container {
      padding: 0;
      padding-top: 50px;

      .navHeaderContainer {
        padding-left: 16px;
        padding-right: 16px;
      }

      .row {
        flex-direction: column;
        gap: 40px;
        margin-bottom: 49px;

        .col {
          padding-left: 16px;
          padding-right: 16px;
          .colHeading {
            min-height: unset;
            margin-bottom: 30px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            color: var(--black-color);
          }
        }
      }

      .buttonContainer {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
