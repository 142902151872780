@import '../../../../../mixins';

.Arrow {
  position: absolute;
  bottom: 111px;

  @include onMobile {
    bottom: 52px;
    width: 86px;
  }

  .arrow {
    opacity: 0;
    transition: opacity 0.5s;

    &.visible {
      opacity: 1;
    }

    @include onMobile {
      width: 84px;
      height: 54px;
    }
  }

  .arrowFill {
    position: absolute;
    transform: scaleX(-1);

    @include onMobile {
      width: 84px;
      height: 54px;
    }
  }

  .arrowHead {
    position: absolute;

    @include onMobile {
      width: 12px;
      height: 12px;
    }
  }
}
