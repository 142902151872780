.Question {
  .title {
    margin-bottom: 9px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  .info {
    margin-bottom: 16px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1e1e1e;
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 1024px) {
  .Question {
    .title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 30px;
    }

    .info {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
