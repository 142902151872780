.CountryCitySelector {
  position: absolute;
  z-index: 1;
  left: 18px;
  bottom: -11px;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  width: 438px;
  padding-top: 23px;
  padding-bottom: 26px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 15px;

  h1 {
    margin: 0;
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
    color: var(--gray-color);
    text-align: left;
  }

  .dropdowns {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    .dropdownContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;

      h2 {
        min-width: 118px;
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 40px;
        color: black;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 16px;

    .clearButton {
      width: 96px;
      height: 35px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: var(--gray-color);
      background-color: #f2f4f8;
      border-radius: 7px;
      border: none;
    }
  }
}

@media (max-width: 1024px) {
  .CountryCitySelector {
    width: 287px;
    left: unset;
    right: -40px;
    h1 {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 25px;
    }

    .dropdowns {
      gap: 28px;
      margin-bottom: 10px;
      .dropdownContainer {
        flex-direction: column;
        align-items: stretch;

        h2 {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .buttons {
      justify-content: space-between;

      button {
        display: block !important;
      }
    }
  }
}
