.DropdownSelector {
  position: relative;
  width: 100%;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &.open {
    box-shadow: none;
  }

  &.hasOption {
    .selector {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 165px;
      padding-bottom: 25px;
    }
  }

  .currentOption {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
  }

  .selector,
  .options .header {
    display: flex;
    justify-content: center;
    //  align-items: center;
    height: 64px;
    padding-top: 22px;
    background-image: var(--icon), url(../../../../assets/images/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: left 16px top 19px, right 16px top 28px;
    background-color: var(--white-color);
    border: 1px solid #f2f4f8;
    border-radius: 15px;

    .title {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: var(--black-color);
    }
  }
}

@media (max-width: 1024px) {
  .DropdownSelector {
    &.hasOption {
      .selector {
        height: 143px;
        padding-bottom: 22px;
      }
    }

    .selector,
    .options .header {
      .title {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: var(--black-color);
      }
    }
  }
}
