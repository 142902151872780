.dialog {
  max-width: 700px;
}

.modalContent {
  border-radius: 20px;
  border: none;
}

.QrCodeModal {
  border-radius: 20px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 68px;
    padding-bottom: 78px;
    background-color: var(--secondary-color);
    border-radius: 20px;

    .closeIcon {
      position: absolute;
      top: 29px;
      right: 24px;
      display: block;
      width: 14px;
      height: 14px;
      margin-left: auto;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L1 13' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L13 13' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      cursor: pointer;
    }
    h1 {
      margin: 0;
      margin-bottom: 20px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 54px;
      text-align: center;
      color: var(--black-color);
    }

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 676px;
      height: 169px;
      margin-bottom: 35px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: var(--black-color);
    }

    .qrCodeContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      margin-bottom: 67px;
      background-color: var(--white-color);
      border-radius: 12px;
      border: 1px solid black;
    }

    .buttons {
      display: flex;
      gap: 16px;
      margin-top: auto;
    }
  }
}

@media (max-width: 1024px) {
  .dialog {
    margin-left: auto;
    margin-right: auto;
  }
}
