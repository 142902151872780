.Timer {
  position: absolute;
  top: 62px;
  left: calc(50% - 37px);
  display: flex;
  align-items: center;
  width: 74px;
  padding-left: 13px;
  height: 28px;
  font-family: 'Sentinel';
  color: var(--white-color);
  font-size: 22px;
  border: 1px solid #f6f7f7;
  text-align: center;
  border-radius: 12px;
}

@media (max-width: 1024px) {
  .Timer {
    top: 158px;
  }
}
