.MainInfoForm {
  display: flex;
  flex-direction: column;
  gap: 39px;

  .row {
    display: flex;
    gap: 33px;

    .col {
      flex: 1;
    }
  }
}

@media (max-width: 1024px) {
  .MainInfoForm {
    gap: 48px;

    .row {
      flex-direction: column;
      gap: 48px;
    }
  }
}
