.MyFavoritesPage {
  min-height: 822px;
  padding-top: 44px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 20px;
  background-color: var(--secondary-color);

  .header {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 22px;

    .backButton {
      margin-bottom: 7px;
      background-color: transparent;
      border: none;
    }

    h2 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
      color: var(--black-color);
    }
  }

  .jobsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@media (max-width: 1024px) {
  .MyFavoritesPage {
    min-height: 1613px;
    padding-top: 33px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 44px;

    .header {
      display: none;
    }
  }
}
