.Success {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 116px;

  img {
    margin-bottom: 51px;
  }

  h3 {
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 53px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--black-color);
  }

  .subtitle {
    width: 331px;
    margin-bottom: 60px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: var(--black-color);
  }

  .info {
    width: 522px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--black-color);
  }
}

@media (max-width: 1024px) {
  .Success {
    padding-bottom: 148px;
    h3 {
      font-size: 36px;
    }

    .subtitle {
      width: 293px;

      font-size: 28px;
      line-height: 38px;
    }

    .info {
      width: 320px;
      margin: 0;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
