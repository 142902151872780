@import '../../../mixins';

.Videos {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--secondary-color);

  @include onMobile {
    padding-left: 34px;
    padding-right: 34px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 75px;
    max-width: 1024px;

    @include onMobile {
      max-width: 100%;
      column-gap: 9px;
      row-gap: 11px;
    }
  }
}
