.dialog {
  max-width: 313px;
  margin-left: auto;
  margin-right: auto;
}

.modalContent {
  border-radius: 24px;
  box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
  border: none;
}

.TakePictureModal {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 277px;
    h1,
    p {
      width: 240px;
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
    }
    h1 {
      padding-top: 25px;
    }
    p {
      font-size: 24px;
      color: var(--brand-color);
    }
    border-radius: 24px;
    background-color: var(--secondary-color);
  }
}
