@import '../../../../mixins';

.ApplyForJob {
  position: relative;
  // left: -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 425px;

  @include onMobile {
    max-width: 121px;
    left: 0;
  }

  .images {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120px;
    width: 100%;
    margin-bottom: 27px;

    @include onMobile {
      bottom: 15px;
    }

    img {
      opacity: 0.4;
      transition: opacity 0.1s ease-in-out;

      &.hovered {
        opacity: 1;
        z-index: 1;
      }
    }

    .employeeOne {
      position: absolute;
      bottom: 10px;
      right: 25px;
      z-index: 1;
      height: 350px;

      @include onMobile {
        right: 0;
        height: 115px;
      }
    }

    .employeeTwo {
      position: absolute;
      left: 35px;
      bottom: 10px;
      height: 350px;

      @include onMobile {
        left: 0;
        height: 120px;
      }
    }
  }

  .button {
    width: 100%;
    width: 425px;
    max-width: 425px;
    height: 96px;
    font-family: 'National 2 Condensed';
    font-size: 35px;
    font-weight: 700;
    line-height: 83px;
    color: var(--white-color);
    text-transform: uppercase;
    white-space: nowrap;
    background-color: var(--brand-color);
    border-radius: 40px;
    border: none;

    @include onMobile {
      width: 121px;
      height: 27px;
      font-size: 10px;
      line-height: 12px;
      border-radius: 8px;
    }
  }
}

@media (max-width: 375px) {
  .ApplyForJob {
    position: relative;
    // left: -25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .button {
      width: 90px;
    }
  }
}
