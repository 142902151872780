.button {
  position: absolute;
  right: 0;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid var(--white-color);
}

.recordButton {
  width: 70px;
  height: 70px;
  padding: 0;
}

.stopButton {
  width: 70px;
  height: 70px;
}

.pauseButton {
  /*  position: absolute;
  right: 68px;
  bottom: 63px; */
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
}

.resumeButton {
  padding: 0;
  border: none;
}

.smallStopButton {
  /*   position: absolute;
  bottom: 63px;
  right: 68px; */
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
}
