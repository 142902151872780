@import '../../../mixins';

.OpenPositions {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 422px;
  padding-left: 116px;
  padding-right: 116px;
  background-color: var(--brand-color);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;

  &:hover {
    .container {
      .scrollButton {
        opacity: 1;
      }
    }
  }

  @include onMobile {
    height: 185px;
    padding-left: 0;
    padding-right: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: visible;
  }

  .container {
    position: relative;
    flex: 1;
    padding-top: 35px;

    @include onMobile {
      padding-top: 11px;
    }

    .header {
      display: flex;
      justify-content: center;

      @include onMobile {
        justify-content: flex-start;
        padding-left: 20px;
      }

      .title {
        font-family: National 2;
        font-size: 36px;
        font-weight: 700;
        line-height: 44.86px;
        text-align: center;
        color: var(--white-color);
        text-transform: uppercase;

        @include onMobile {
          font-size: 18px;
          line-height: 22px;
        }

        .animated {
          display: inline-block;
          transform: translateY(150%);
          opacity: 0;

          &.inView {
            animation: slideUp 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
          }
        }
      }

      .locationPicker {
        position: absolute;
        top: 45px;
        right: 0;

        @include onMobile {
          top: 15px;
          right: 19px;
        }
      }
    }

    .scrollButton {
      position: absolute;
      top: 142px;
      bottom: 60px;
      left: -100px;
      width: 100px;
      opacity: 0;
      background-color: transparent;
      border: none;
      transition: opacity 0.5s ease;

      &.animating {
        animation: shrink-grow-left 0.3s;

        &.right {
          animation: shrink-grow-right 0.3s;
        }
      }

      @include onMobile {
        display: none;
      }

      &.right {
        left: auto;
        right: -100px;
        transform: scaleX(-1);
      }
    }

    .outerContainer {
      display: flex;
      height: 217px;
      padding-top: 55px;
      box-sizing: content-box;

      @include onMobile {
        padding-top: 0;
      }

      .noData {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 37px;
        font-family: 'National 2 Condensed';
        font-size: 36px;
        font-weight: 400;
        line-height: 41.11px;
        text-align: center;
        color: var(--white-color);
        white-space: pre-line;

        @include onMobile {
          font-size: 20px;
          line-height: 22px;
          padding-top: 20px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .overflowWrapper {
        width: 100%;
        position: relative;
        overflow: hidden;

        @include onMobile {
          overflow: auto;
          // Hide scrollbar for mobile
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
          }
        }

        .innerContainer {
          position: absolute;
          display: flex;
          gap: 17px;
          transition: all 0.5s ease;

          @include onMobile {
            gap: 15px;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(150%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shrink-grow-left {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink-grow-right {
  0% {
    transform: scale(-1);
  }
  50% {
    transform: scale(-0.9);
  }
  100% {
    transform: scale(-1);
  }
}
