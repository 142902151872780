.ProfileForm {
  padding-top: 32px;
  padding-bottom: 41px;
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;

  h1 {
    margin: 0;
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 50px;
    text-align: center;
    color: var(--black-color);
  }

  p {
    margin: 0;
    margin-bottom: 41px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 50px;
    text-align: center;
    color: var(--black-color);
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 61px;
  }
}

@media (max-width: 1024px) {
  .ProfileForm {
    width: 100%;
    padding-top: 1px;
    padding-bottom: 32px;
    padding-left: 14px;
    padding-right: 14px;
    h1 {
      margin-bottom: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      text-align: center;
      color: var(--black-color);
    }

    p {
      margin-bottom: 31px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      text-align: center;
      color: var(--black-color);
    }

    form {
      gap: 48px;
      margin-bottom: 65px;
    }
  }
}
