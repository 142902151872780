.SetInterview {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 89px;

  .backButton {
    position: absolute;
    top: 247px;
    left: 48px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }

  .buttons {
    position: relative;
    display: flex;
    gap: 27px;
    margin-top: 108px;
  }
}

@media (max-width: 1024px) {
  .SetInterview {
    padding-top: 56px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 31px;

    &.decline {
      .backButton {
        top: 197px;
        left: 15px;
      }
    }

    .buttons {
      flex-direction: column;
      width: 100%;
    }
  }
}
