.Congratulations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 143px;
  padding-bottom: 148px;

  .heading {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    color: var(--black-color);
  }
}

@media (max-width: 1024px) {
  .Congratulations {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 68px;
    .heading {
      margin-bottom: 120px;
    }
  }
}
