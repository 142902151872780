.options {
  // position: absolute;
  position: relative;
  // top: 0;
  top: -165px;
  z-index: 1;
  width: 100%;
  margin-bottom: -165px;
  background-color: var(--white-color);
  border: 1px solid #f2f4f8;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .header {
    display: flex;
    justify-content: center;
    // align-items: center;
    height: 64px;
    padding-top: 22px;
    background-image: url(../../../../assets/images/education.svg),
      url(../../../../assets/images/arrow-up.svg);
    background-repeat: no-repeat;
    background-position: left 16px top 19px, right 16px top 28px;
    background-color: var(--white-color);
    border-radius: 15px;

    .title {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: var(--black-color);
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-height: 263px;
    margin: 0;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 10px;
    padding-bottom: 25px;
    list-style: none;
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 64px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      /* or 100% */

      display: flex;
      align-items: center;
      text-align: center;

      color: var(--gray-color);

      border: 1px solid #f2f4f8;
      border-radius: 15px;

      &.active {
        background-color: var(--black-color);
        border: 1px solid #f2f4f8;
        border-radius: 15px;
        color: var(--white-color);
      }
    }
  }
}

.currentOption {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--white-color);
  background-color: var(--black-color);
  border: 1px solid #f2f4f8;
  border-radius: 15px;
}

@media (max-width: 1024px) {
  .selectedDate {
    height: 48px;
    font-size: 16px;
  }

  .currentOption {
    height: 48px;
    font-size: 16px;
  }

  .options {
    ul {
      li {
        min-height: 48px;
        height: 48px;
        font-size: 16px;
      }
    }
    .header {
      .title {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: var(--black-color);
      }
    }
  }
}
