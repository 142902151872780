.dialog {
  max-width: 510px;
}

.modalContent {
  border-radius: 20px;
  overflow: hidden;
}

.PreviousJobApplicationModal {
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 31px;
    padding-left: 30px;
    padding-right: 45px;
    padding-bottom: 43px;
    background-color: var(--secondary-color);

    .closeIcon {
      position: absolute;
      top: 47px;
      right: 22px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.9252 6.40234L6.90161 18.5145' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.90172 6.40234L18.9253 18.5145' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    h1 {
      margin-bottom: 33px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 28px;
      color: var(--black-color);
    }

    .resumes {
      display: flex;
      justify-content: space-between;
    }
  }
}
