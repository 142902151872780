.Notifications {
  position: fixed;
  top: 100px;
  left: 51.5%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 703px;
  height: 665px;
  padding-bottom: 36px;
  background-color: var(--secondary-color);
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .notificationsListContainer {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    overflow-y: auto;
    scrollbar-color: var(--brand-color) transparent;
    scrollbar-width: thin;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .noData {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    p {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
      text-align: center;
      color: var(--gray-color);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 101px;
    padding-left: 40px;
    padding-right: 40px;

    .readAllButton {
      padding: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration-line: underline;
      color: var(--black-color);
      background-color: transparent;
      border: none;
    }

    .heading {
      display: flex;
      align-items: center;
      gap: 20px;

      h3 {
        width: 183px;
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 50px;
        color: var(--black-color);
      }

      .unreadCount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 30px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        color: var(--white-color);
        background-color: var(--brand-color);
        border-radius: 20px;

        &.read {
          background-color: var(--gray-color);
        }
      }
    }
  }
}

@media (max-width: 1470px) {
  .Notifications {
    left: 44%;
  }
}

@media (max-width: 1024px) {
  .Notifications {
    top: 78px;
    left: 0;
    right: 0;
    bottom: 0;
    width: unset;
    height: unset;
    border-radius: 0;
    box-shadow: none;

    .notificationsListContainer {
      padding-top: 22px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .noData {
      p {
      }
    }

    .header {
      height: 50px;
      padding-left: 16px;
      padding-right: 16px;

      .heading {
        gap: 17px;
        h3 {
          width: unset;
          font-size: 28px;
        }

        .unreadCount {
          display: flex;
          align-items: center;
          width: 56px;
          height: 35px;
          padding-top: 3px;
          font-size: 22px;
        }
      }
    }
  }
}
