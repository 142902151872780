.SortOrderDropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: 185px;
  height: 100%;

  .selector {
    position: relative;
    width: 100%;
    padding-top: 3px;
    padding-left: 55px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    color: var(--brand-color);
    cursor: pointer;

    &.open {
      .arrow {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.81923 1.17033L11.458 7.79715C12.1075 8.72504 11.4437 10 10.3111 10L1.68892 10C0.556284 10 -0.107528 8.72504 0.541994 7.79715L5.18077 1.17033C5.57887 0.601614 6.42113 0.601616 6.81923 1.17033Z' fill='%23D52D34'/%3E%3C/svg%3E%0A");
      }
    }

    .arrow {
      position: absolute;
      top: calc(50% - 5px);
      right: 43px;
      width: 12px;
      height: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.18077 8.82967L0.541993 2.20285C-0.107529 1.27496 0.556286 -8.85647e-07 1.68892 -8.36138e-07L10.3111 -4.59251e-07C11.4437 -4.09742e-07 12.1075 1.27496 11.458 2.20285L6.81923 8.82967C6.42113 9.39839 5.57887 9.39838 5.18077 8.82967Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    }
  }

  .options {
    position: absolute;
    z-index: 1;
    bottom: -4px;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    list-style: none;
    background-color: var(--white-color);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
      0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 15px;

    li {
      padding-left: 23px;
      padding-right: 5px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 40px;
      cursor: pointer;

      &.active {
        color: var(--brand-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .SortOrderDropdown {
    width: 87px;
    .selector {
      padding-left: 20px;
      padding-right: 34px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;

      .arrow {
        top: calc(50% - 2.5px);
        width: 6px;
        height: 5px;
        right: 12px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .options {
      li {
        padding-left: 14px;
        padding-right: 3px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
