@import '../../../mixins';

.Faces {
  position: relative;
  // aspect-ratio: 366 / 624;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 451px;
  height: 100%;
  background-image: url(../../../assets/images/landing/sanders.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--secondary-color);

  @include onMobile {
    position: absolute;
    right: 5%;
    bottom: 0;
    width: 48%;
    background-position: center bottom 240px;
  }

  &:not(.animating) {
    .faceContainer {
      opacity: 0;
    }
  }

  .faceContainer {
    position: absolute;
    aspect-ratio: 366 / 624;
    left: 0;
    // top: 50%;
    //  transform: translateY(-50%);
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s;

    @include onMobile {
      bottom: 240px;
    }

    .face {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - 18%));
      width: var(--background-image-width);
      height: calc(var(--background-image-height) * 0.8);
      background-color: var(--secondary-color);

      .faceImage {
        //  display: none;
        position: absolute;
        top: 4%;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s;
        object-fit: contain;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}
