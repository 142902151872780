@import '../../../../mixins';

.FromMemberToManager {
  &.variantTwo {
    .images {
      img {
        &.employeeTwo {
          left: 0;
          bottom: 0;
          height: 290px;
          bottom: 30px;

          @include onMobile {
            height: 95px;
            bottom: 0;
          }
        }

        &.employeeOne {
          right: -10px;
          height: 300px;
          bottom: 30px;
          @include onMobile {
            position: absolute;
            bottom: 0;
            height: 100px;
          }
        }
      }
    }
  }

  .images {
    position: absolute;
    margin-bottom: 25px;
    left: 0;
    right: 0;
    bottom: 103px;

    @include onMobile {
      bottom: 40px;
      display: flex;
      justify-content: flex-end;
      padding-left: 0;
      margin-bottom: 10px;
    }

    img {
      object-fit: contain;
      transform: scale(0);
      transform-origin: center bottom;
      transition: transform 1.1s cubic-bezier(0.25, 0.8, 0.25, 1);

      &.visible {
        transform: scale(1);
      }

      &.employeeOne {
        position: absolute;
        z-index: 1;
        bottom: 30px;
        right: 0;
        height: 300px;

        @include onMobile {
          height: 100px;
          bottom: 0;
        }
      }

      &.employeeTwo {
        position: absolute;
        bottom: 30px;
        left: -10px;
        height: 270px;

        @include onMobile {
          height: 90px;
          bottom: 0;
        }
      }
    }
  }

  .arrow {
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 1s cubic-bezier(0, 0, 0.2, 1),
      transform 1s cubic-bezier(0, 0, 0.2, 1);

    @include onMobile {
      width: 18px;
    }

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    path {
      fill: var(--brand-color);
    }
  }

  .progress {
    position: relative;
    display: flex;
    align-items: center;
    gap: 19px;

    @include onMobile {
      gap: 10px;
    }

    .jobPosition {
      font-family: 'National 2 Condensed';
      font-size: 26px;
      font-weight: 700;
      line-height: 29.69px;
      text-align: center;
      white-space: pre-line;
      text-transform: uppercase;
      color: var(--black-color);

      &:last-child {
        .animation {
          transform: translateX(-50%);
          transition: opacity 1.2s cubic-bezier(0, 0, 0.2, 1),
            transform 1.2s cubic-bezier(0, 0, 0.2, 1);
        }
      }

      .animation {
        display: inline-block;
        opacity: 0;
        transform: translateX(-120%);
        transition: opacity 1s cubic-bezier(0, 0, 0.2, 1),
          transform 1s cubic-bezier(0, 0, 0.2, 1);

        &.visible {
          opacity: 1;
          transform: translateX(0);
        }
      }

      @include onMobile {
        font-size: 8px;
        line-height: 9px;
      }
    }
  }
}
