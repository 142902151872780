.RecordVideoProgress {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .step {
    display: flex;
    align-items: center;
    gap: 20px;

    &.completed {
      .number {
        background-color: #00e4ac;
        color: var(--white-color);

        &::after {
          background-color: #00e4ac;
        }
      }
    }

    &.active {
      .number {
        background-color: var(--brand-color);
        color: var(--white-color);
      }
    }

    .number {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      padding-top: 2px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: var(--black-color);
      background-color: var(--white-color);
      border-radius: 12px;

      &.lastOne {
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25 5.25L3.75 7.75L4.21546 7.28454M6.96455 4.03545L9.75 1.25M5.25 5.25L7.75 7.75L13.75 1.25' stroke='%23242833' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateY(100%) translateX(-50%);
        display: block;
        width: 2px;
        height: 54px;
        background-color: var(--gray-color);
      }
    }

    .info {
      display: flex;
      align-items: center;
      gap: 10px;

      h2 {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: var(--black-color);
      }

      span {
        color: var(--gray-color);
      }
    }
  }
}
