.EditProfilePage {
  padding-top: 48px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 50px;
  background-color: var(--secondary-color);

  .container {
    position: relative;

    .header {
      display: flex;
      align-items: flex-end;
      height: 68px;
      margin-bottom: 57px;
    }

    .content {
      display: flex;
      gap: 32px;

      .col {
        display: flex;
        flex-direction: column;

        &.flexOne {
          flex: 1;
        }

        .avatarContainer {
          display: flex;
          gap: 31px;
          margin-bottom: 135px;

          .userName {
            max-width: 270px;
            margin-bottom: 20px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 36px;
            color: var(--black-color);
            word-break: break-all;
          }
        }

        .resumeContainer {
          h2 {
            margin-bottom: 22px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 30px;
            color: var(--black-color);
          }

          .resumes {
            display: flex;
            gap: 32px;
          }
        }

        .basicInformationContainer {
          margin-bottom: 60px;
        }

        .mainInfoForm {
          margin-bottom: 64px;
        }

        .buttons {
          display: flex;
          justify-content: center;
          gap: 33px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .EditProfilePage {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 92px;
    .container {
      .content {
        flex-direction: column;
        gap: 0;
        .col {
          &.flexOne {
            flex: unset;
          }

          .avatarContainer {
            width: 100%;
            gap: 16px;
            margin-bottom: 40px;

            .userBlock {
              flex: 1;
            }
            .userName {
              margin-bottom: 19px;
              font-size: 28px;
              line-height: 36px;
            }
          }

          .resumeContainer {
            h2 {
              display: none;
            }
          }

          .basicInformationContainer {
            margin-bottom: 64px;
          }

          .mainInfoForm {
            margin-bottom: 64px;
          }

          .buttons {
            flex-direction: column;
            gap: 30px;
          }
        }
      }
    }
  }
}
