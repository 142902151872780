.DeclineOffer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 115px;

  .backButton {
    position: absolute;
    top: 247px;
    left: 48px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }

  .heading {
    margin-bottom: 38px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 40px;
    text-align: center;
    color: var(--black-color);
  }

  .dropdownContainer {
    width: 633px;
    margin-bottom: 38px;

    .subtitle {
      margin-bottom: 22px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 50px;
      color: var(--black-color);
    }
  }

  .buttons {
    display: flex;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .DeclineOffer {
    padding-top: 56px;
    .backButton {
    }

    .heading {
      margin-bottom: 35px;
    }

    .dropdownContainer {
      width: 100%;
      .subtitle {
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 26px;
      }
    }

    .buttons {
      width: 100%;
      flex-direction: column;
    }
  }
}
