.selectedDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--white-color);
  background-color: var(--black-color);
  border-radius: 15px;

  span {
    position: static;
    transform: none;
    display: inline-flex;
    align-items: center;
    width: calc(100% / 3);
    padding-left: 51px;
    align-self: stretch;
    vertical-align: middle;

    &.month {
      justify-content: center;
      padding-left: 10px;
      padding-left: 0;
      position: relative;
      left: -10px;
    }

    &:last-child {
      justify-content: flex-end;
      padding-left: 0;
      padding-right: 51px;
    }
  }
}

.month {
  position: absolute;
  left: 50%;
  transform: translateX(calc(-50% - 10px));
}

.options .header {
  display: flex;
  justify-content: center;
  height: 64px;
  background-image: url(../../../../assets/images/cake.svg),
    url(../../../../assets/images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: left 16px top 19px, right 16px top 28px;
  background-color: var(--white-color);
  border: 1px solid #f2f4f8;
  border-radius: 15px;

  .title {
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: var(--black-color);
  }
}

.options {
  position: relative;
  z-index: 1;
  top: -165px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 370px;
  margin-bottom: -165px;
  background-color: var(--white-color);
  border: 1px solid #f2f4f8;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .header {
    background-image: url(../../../../assets/images/cake.svg),
      url(../../../../assets/images/arrow-up.svg);
    border: none;

    span {
      position: relative;
      top: 5px;
    }
  }

  .dates {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding-bottom: 25px;
    padding-left: 22px;
    padding-right: 22px;

    .day {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex: 1;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: var(--gray-color);
      cursor: pointer;

      span {
        position: static;
        transform: none;
        display: inline-flex;
        align-items: center;
        width: calc(100% / 3);
        align-self: stretch;
        vertical-align: middle;

        &.month {
          justify-content: center;
          padding-left: 0;
          position: relative;
          left: 0;
          transform: none;
        }

        &:last-child {
          justify-content: flex-end;
          padding-left: 0;
        }
      }
    }

    .prevDay1,
    .prevDay2 {
      span {
        align-items: flex-start;
      }
    }

    .nextDay1,
    .nextDay2 {
      span {
        align-items: flex-end;
      }
    }

    .prevDay1,
    .nextDay1 {
      // padding-left: 56px;
      //  padding-right: 56px;

      span {
        padding-left: 56px;
        &.month {
          left: -5px;
        }

        &:last-child {
          padding-right: 56px;
        }
      }
    }

    .prevDay2,
    .nextDay2 {
      span {
        padding-left: 63px;
        &.month {
          left: 0px;
        }

        &:last-child {
          padding-right: 63px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .selectedDate {
    height: 48px;
    font-size: 16px;
  }

  .options .header {
    .title {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: var(--black-color);
    }
  }
}
