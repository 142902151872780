.RecordVideoPage {
  background-color: var(--secondary-color);
  padding-bottom: 41px;
  padding-left: 70px;
  padding-right: 70px;

  .header {
    position: relative;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding-top: 65px;
  }
}

@media (max-width: 1024px) {
  .RecordVideoPage {
    padding-left: 14px;
    padding-right: 14px;

    .container {
      padding-top: 42px;
    }
  }
}
