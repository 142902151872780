/* Customize the label (the container) */
.Checkbox {
  display: flex;
  align-items: center;
  position: relative;
  height: 15px;
  padding-left: 22px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    input ~ .checkmark {
      background-color: #ccc;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: var(--brand-color);

        &::after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 15px;
    width: 15px;
    background-color: #f2f4f8;
    border-radius: 4px;

    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 2px;
      left: 5.5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
