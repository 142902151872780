.DropOffPage {
  padding-top: 46px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 252px;
  background-color: var(--secondary-color);
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 58px;

    .row {
      display: flex;
      gap: 20px;
      width: 100%;
      margin-bottom: 171px;
      .col {
        flex: 1;
        .colHeading {
          min-height: 144px;
          margin: 0;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 46px;
          line-height: 55px;
          color: var(--black-color);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .DropOffPage {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 49px;
    .container {
      padding-top: 50px;
      .row {
        flex-direction: column;
        gap: 40px;
        margin-bottom: 49px;
        .col {
          .colHeading {
            min-height: unset;
            margin-bottom: 30px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            color: var(--black-color);
          }
        }
      }
    }
  }
}
