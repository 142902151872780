.ContactUs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 628px;
  padding-top: 156px;

  .container {
    width: 100%;

    .textareaContainer {
      margin-bottom: 28px;
      padding-top: 29px;
      padding-left: 36px;
      padding-right: 36px;
      padding-bottom: 29px;
      background-color: #f2f4f8;
      border-radius: 20px;
    }

    .tip {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: var(--black-color);
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .ContactUs {
    align-items: center;
    width: 100%;
    padding-top: 95px;

    .container {
      .textareaContainer {
        padding-top: 24px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 24px;
      }

      .tip {
        margin-bottom: 24px;
      }
    }

    .buttons {
      flex-direction: column;
      gap: 27px;
    }
  }
}
