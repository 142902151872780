.calendarButton {
  position: absolute;
  left: 169px;
  top: -79px;
}

@media (max-width: 1024px) {
  .calendarButton {
    left: 0;
    right: 0;
  }
}
