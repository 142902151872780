.CounterOffer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 140px;

  .backButton {
    position: absolute;
    top: 255px;
    left: 53px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }

  .buttons {
    display: flex;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .CounterOffer {
    .backButton {
      left: 15px;
    }

    .buttons {
      width: 100%;
      flex-direction: column;
    }
  }
}
