.dialog {
  max-width: 908px;
  border-radius: 20px;
}

.modalContent {
  border-radius: 20px;
}

.Modal {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 833px;
    padding-top: 60px;
    padding-bottom: 60px;
    // padding-top: 108px;
    // padding-bottom: 141px;
    background-color: var(--secondary-color);
    border-radius: 20px;

    .formContainer {
      width: 506px;
    }

    .closeButton {
      position: absolute;
      top: 29px;
      right: 32px;
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width: 1024px) {
  .Modal {
    .content {
      max-height: 700px;
      padding-top: 30px;
      padding-bottom: 30px;
      overflow: hidden;

      .formContainer {
        width: 100%;
      }

      .closeButton {
        top: 10px;
        right: 14px;
      }
    }
  }
}
