.Reschedule {
  .calendarContainer {
    margin-bottom: 30px;
  }

  .backButton {
    position: absolute;
    top: 247px;
    left: 48px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .Reschedule {
    width: 100%;
    .backButton {
      top: 197px;
      left: 15px;
    }
  }
}
