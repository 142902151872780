.heading {
  margin-bottom: 44px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 40px;
  text-align: center;
  color: var(--black-color);
}

@media (max-width: 1024px) {
  .heading {
    margin-bottom: 35px;
  }
}
