.PhoneSelector {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    margin-bottom: 4px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: var(--black-color);
  }

  .error {
    position: absolute;
    bottom: -24px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ff2d55;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: 54px;
    background: var(--white-color);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
      0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 10px;

    .dropdown {
      height: 100%;
      position: absolute;
      left: 0;
      padding: 0 12px 0 15px;
      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      border-radius: 10px;

      .country {
        width: 30px;
      }
    }

    .search {
      position: absolute;
      left: 15px;
    }
  }

  input {
    padding-left: 50px;
    padding-right: 15px;
    height: 100%;
    width: 100%;
    padding-top: 5px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--black-color);
    border-radius: 10px;
    border: none;

    &::placeholder {
      color: var(--gray-color);
    }

    &:focus {
      outline: none;
    }
  }
}
