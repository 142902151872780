.OtpVerificationForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 12px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  // overflow-y: auto;

  p {
    margin: 0;
  }

  .logo {
    margin-bottom: 30px;
  }

  h1 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 60px;
    text-align: center;
    color: var(--black-color);
  }

  .info {
    width: 350px;
    margin-bottom: 30px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: var(--black-color);
  }

  .email {
    margin-bottom: 30px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--black-color);
  }

  h2 {
    margin: 0;
    margin-bottom: 21px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--black-color);
  }

  .inputs {
    margin-bottom: 33px;
    & > div {
      & > div {
        display: flex;
        gap: 30px;
      }
    }
    .input {
      width: 50px;
      height: 50px;
      margin: 0;
      font-family: 'National 2 Condensed';
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--black-color);
      border-radius: 10px;
      border: none;
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.084))
        drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.168));

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--black-color);
        font-family: 'Gilroy-Medium' !important;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;
      }
    }
  }

  .verifyButtonContainer {
    width: 444px;
    margin-bottom: 10px;
  }

  .notReceived {
    width: 210px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--black-color);

    .clickHere {
      color: var(--brand-color);
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .OtpVerificationForm {
    width: 100%;
    padding-top: 10;
    padding-bottom: 13px;
    padding-left: 14px;
    padding-right: 14px;

    .logo {
      width: 140px;
      margin-bottom: 23px;
    }

    h1 {
      margin-bottom: 27px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 40px;
      text-align: center;
      color: var(--black-color);
    }

    .info {
      width: 260px;
      margin-bottom: 20px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: var(--black-color);
    }

    .email {
      margin-bottom: 19px;
    }

    h2 {
      margin-bottom: 40px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      text-decoration-line: underline;
      color: var(--black-color);
    }

    .inputs {
      width: 100%;
      margin-bottom: 40px;
      & > div {
        & > div {
          width: 100%;
          justify-content: space-between;
          gap: 0;
        }
      }
    }

    .verifyButtonContainer {
      width: 100%;
    }
  }
}
