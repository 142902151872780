.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1001;
  display: none;
  background-color: var(--black-color);
  opacity: 0.3;

  &.visible {
    display: block;
  }
}

.UserMenu {
  position: fixed;
  z-index: 1002;
  top: 0;
  bottom: 0;
  right: -100%;
  display: flex;
  flex-direction: column;
  width: 746px;
  background-color: var(--white-color);
  overflow-y: auto;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;

  &.visible {
    right: 0;
  }

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 63px;
    padding-top: 129px;

    .logo {
      position: absolute;
      top: 30px;
      left: 16px;
      display: none;
      width: 40px;
    }

    .closeIcon {
      position: absolute;
      top: 62px;
      right: 76px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../../assets/images/close-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    .userPhoto {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-bottom: 45px;
    .navigation {
      padding-left: 216px;
      margin-bottom: 80px;

      .navList {
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          a {
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            color: black;
            text-decoration: none;
          }
        }
      }
    }

    .logoutButton {
      margin-top: auto;
      margin-left: 216px;
      padding: 0;
      width: 67px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      color: var(--gray-color);
      background-color: transparent;
      border: none;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1024px) {
  .UserMenu {
    width: 100%;
    right: -2000px;
    background-color: var(--secondary-color);

    &.visible {
      left: 0;
      right: 0;
    }

    .header {
      padding-top: 89px;

      .logo {
        display: block;
      }

      .closeIcon {
        top: 31px;
        right: 18px;
      }
    }

    .content {
      padding-bottom: 61px;

      .navigation {
        padding-left: 57px;
        .navList {
          li {
            a {
              font-weight: 500;
              font-size: 28px;
            }
          }
        }
      }

      .logoutButton {
        margin-left: 57px;
      }
    }
  }
}
