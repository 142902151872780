.Review {
  display: flex;
  justify-content: center;
  padding-top: 228px;

  p {
    width: 439px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    color: var(--black-color);
  }
}

@media (max-width: 1024px) {
  .Review {
    padding-top: 174px;
    padding-left: 15px;
    padding-right: 15px;
  }

  p {
    width: unset;
  }
}
