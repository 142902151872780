@import '../../../mixins';

.CareerRoadmap {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 803px;
  margin-top: -45px;
  padding-top: 48px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: var(--brand-color);
  border-radius: 50px;

  @include onMobile {
    height: unset;
    margin-top: -26px;
    padding-top: 11px;
    padding-bottom: 16px;
    border-radius: 30px;
  }

  .title {
    margin-bottom: 30px;
    font-family: 'National 2 Condensed';
    font-size: 80px;
    font-weight: 700;
    line-height: 91px;
    text-align: center;
    color: var(--white-color);
    text-transform: uppercase;

    @include onMobile {
      margin-bottom: 25px;
      font-size: 23px;
      line-height: 26px;
    }

    .animated {
      display: inline-block;
      transform: translateY(120%);
      opacity: 0;

      &.inView {
        animation: slideUp 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
      }
    }
  }

  .employees {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1160px;
    margin: 0;
    padding: 0;
    list-style: none;

    @include onMobile {
      justify-content: space-between;
      align-self: center;
      width: 100%;
      max-width: 343px;
      gap: 5px;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(120%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
