.SimpleStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  // min-width: 212px;
  height: 140px;
  background-color: var(--white-color);
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .value {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 40px;
    color: var(--black-color);
  }

  .noValue {
    color: var(--gray-color);
  }

  .title {
    margin: 0;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: var(--black-color);
  }
}

@media (max-width: 1024px) {
  .SimpleStat {
    flex: 1;
  }
}
