.Dropdown {
  position: relative;
  .selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 86px;
    height: 36px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    background-color: rgba(118, 118, 128, 0.12);
    border-radius: 6px;
    cursor: pointer;
  }

  .options {
    position: absolute;
    z-index: 2;
    bottom: -167px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 165px;
    gap: 16px;
    margin: 0;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    list-style: none;
    background-color: var(--white-color);
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-y: auto;

    li {
      display: flex;
      justify-content: center;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: var(--black-color);
      cursor: pointer;

      &.active {
        color: var(--brand-color);
      }
    }
  }
}
