.Status {
  padding-left: 90px;
  padding-right: 80px;

  &.withoutAssessment {
    .steps {
      .step {
        &.completed,
        &.active {
          &.first {
            &::after {
              display: none;
            }
          }

          &::after {
            width: 149px;
          }
        }
      }
    }
  }

  .steps {
    position: relative;
    display: flex;
    justify-content: space-between;

    .line {
      position: absolute;
      left: 0;
      top: 16px;
      width: 100%;
      height: 2px;
      background-color: #f2f4f8;
    }

    .step {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.active {
        &.rejected {
          .icon {
            color: var(--white-color);
            background-color: var(--brand-color);
          }

          h3 {
            color: var(--brand-color);
          }
        }

        .icon {
          color: var(--black-color);
          background-color: var(--secondary-color);
          border: none;
        }
      }

      &.completed {
        .icon {
          font-size: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5.375L5.625 9.5L12.5 1.25' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center;
          background-color: #0acf83;
          border: none;
        }
      }

      &.completed,
      &.active {
        &.finished {
          .icon {
            background-color: #00e4ac;
          }
        }

        &.first {
          &::after {
            display: none;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 16px;
          left: 0;
          transform: translateX(-100%);
          display: block;
          width: 104px;
          height: 2px;
          background-color: #00e4ac;
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        color: var(--gray-color);
        background-color: var(--white-color);
        border: 2px solid #f2f4f8;
        border-radius: 50%;
      }

      h3 {
        position: absolute;
        top: 44px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 16px;
        text-align: center;
        color: var(--black-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .Status {
    padding-left: 28px;
    padding-right: 39px;

    &.withoutAssessment {
      .steps {
        .step {
          &.completed,
          &.active {
            &::after {
              width: calc((100vw - 67px) / 3 - 32px);
            }
          }
        }
      }
    }

    .steps {
      .line {
        top: 11px;
      }

      .step {
        .icon {
          width: 24px;
          height: 24px;
          padding-top: 3px;

          font-size: 14px;
          line-height: 14px;
        }

        &.completed,
        &.active {
          &::after {
            top: 11px;
            width: calc((100vw - 67px) / 4 - 30px);
            ///  background-color: blue;
          }
        }

        h3 {
          font-size: 14px;
          line-height: 16px;
          top: 30px;
        }
      }
    }
  }
}
