.dialog {
  max-width: 908px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  padding: 15px 15px;
  border: none;
  position: relative;
  .closeBtn {
    position: absolute;
    top: 25px;
    right: 30px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 50%;
    z-index: 1;
  }
  .currentPositionBtn {
    position: absolute;
    bottom: 55px;
    right: 25px;
    background-color: #fff;
    padding: 10px;
    margin: 0;
    border: none;
    border-radius: 5px;
    z-index: 1;
  }
  .map {
    width: 100%;
    height: 760px;
  }
  .infoMockup {
    width: 336px;
    padding: 20px 30px;
    border-radius: 12px;
    display: flex;
    gap: 44px;
    font-family: 'National 2 Condensed';
    p {
      margin: 0;
      padding: 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li:not(:last-child) {
      margin-bottom: 2px;
    }
    &__logo-wrap {
      padding: 17px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__content-wrap {
      width: 100%;
    }
    .closeBtnInfo {
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 50%;
      z-index: 1;
    }
    &__content-city {
      // font-family: 'Gilroy-SemiBold';
      font-size: 18px;
      line-height: 1.71;
      color: var(--black-color);
    }
    &__content-name {
      // font-family: 'Gilroy-SemiBold';
      font-size: 18px;
      line-height: 1.71;
      color: var(--black-color);
    }
    &__content-address {
      // font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 1.16;
      letter-spacing: 0.01em;
      color: var(--gray-color);
    }
  }
}
@media (max-width: 1024px) {
  .dialog {
    margin-left: auto;
    margin-right: auto;
  }
}
