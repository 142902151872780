.UserBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  .userPhoto {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }

  .userName {
    margin: 0;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 40px;
    color: var(--black-color);
  }
}
