.AdditionalInfoForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 20px;
  margin-left: 100px;

  .title {
    margin: 0;
    margin-bottom: 22px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 60px;
    text-align: center;
    color: var(--black-color);
  }

  .row {
    display: flex;
    gap: 20px;
    width: 100%;

    &.docUploaderContainer {
      padding-left: 117px;
      padding-right: 117px;
    }

    &.buttonContainer {
      justify-content: center;
      padding-top: 20px;

      button {
        max-width: 444px;
      }
    }

    .col {
      flex: 1;
    }
  }
}

@media (max-width: 1024px) {
  .AdditionalInfoForm {
    margin-left: 0;

    .buttonContainer {
      button {
        min-width: 100%;
      }
    }

    .row {
      flex-direction: column;

      &.docUploaderContainer {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
