@import '../../../../mixins';

.ApplyButton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 473px;
  aspect-ratio: 473 / 600;
  padding-top: 112px;
  padding-bottom: 115px;
  padding-left: 61px;
  padding-right: 53px;
  background-color: var(--brand-color);
  border-radius: 33px;
  overflow: hidden;
  container-type: size;

  @include onMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    max-width: 174px;
    aspect-ratio: 174 / 222;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
    aspect-ratio: unset;
    border-radius: 6px;
  }

  .title,
  .text {
    margin-bottom: 0;
    font-family: 'National 2 Condensed';
    font-size: 65px;
    font-weight: 700;
    line-height: 74px;
    text-align: center;

    @include onMobile {
      font-size: 28px;
      line-height: 32px;
    }
  }

  .title {
    margin-bottom: 0;
    color: var(--black-color);
  }

  .text {
    color: var(--white-color);
    text-transform: uppercase;
  }
}

@media (max-width: 1267px) and (min-width: 1024px) {
  .ApplyButton {
    padding-top: 60px;
    padding-bottom: 70px;
    .title,
    .text {
      font-size: 64px;
      line-height: 70px;
    }
  }
}

@media (max-width: 400px) {
  .ApplyButton {
    gap: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    .title,
    .text {
      font-size: 5vw;
      line-height: 6vw;
    }
  }
}
