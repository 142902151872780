.Header {
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 43px;
  padding-right: 53px;

  a.details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    .icon {
      width: 40px;
      height: 40px;
      margin-bottom: 4px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.1556 4.11198C13.7889 4.11198 13.5444 3.86753 13.5444 3.50087V1.66753H6.21111V3.50087C6.21111 3.86753 5.96667 4.11198 5.6 4.11198C5.23334 4.11198 4.98889 3.86753 4.98889 3.50087V1.05642C4.98889 0.689757 5.23334 0.445312 5.6 0.445312H14.1556C14.5222 0.445312 14.7667 0.689757 14.7667 1.05642V3.50087C14.7667 3.86753 14.5222 4.11198 14.1556 4.11198Z' fill='%231E1E1E'/%3E%3Cpath d='M18.4333 2.89062H1.3222C0.649976 2.89062 0.0999756 3.44062 0.0999756 4.11285V16.3351C0.0999756 17.0073 0.649976 17.5573 1.3222 17.5573H18.4333C19.1055 17.5573 19.6555 17.0073 19.6555 16.3351V4.11285C19.6555 3.44062 19.1055 2.89062 18.4333 2.89062ZM1.3222 16.3351V10.8351H7.43331C7.79998 10.8351 8.04442 10.5906 8.04442 10.224C8.04442 9.85729 7.79998 9.61285 7.43331 9.61285H1.3222V4.11285H18.4333V9.61285H12.3222C11.9555 9.61285 11.7111 9.85729 11.7111 10.224C11.7111 10.5906 11.9555 10.8351 12.3222 10.8351H18.4333V16.3351H1.3222Z' fill='%231E1E1E'/%3E%3Cpath d='M12.3221 12.0573H7.43325C7.06659 12.0573 6.82214 11.8128 6.82214 11.4462V9.00174C6.82214 8.63507 7.06659 8.39062 7.43325 8.39062H12.3221C12.6888 8.39062 12.9333 8.63507 12.9333 9.00174V11.4462C12.9333 11.8128 12.6888 12.0573 12.3221 12.0573ZM8.04437 10.8351H11.711V9.61285H8.04437V10.8351Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: #f2f4f8;
      border-radius: 50%;
    }

    span {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .title {
    max-width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 16px;
    overflow: hidden;

    h2 {
      max-width: 100%;
      margin-bottom: 5px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 40px;
      color: var(--black-color);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .location {
      display: flex;
      align-items: center;
      gap: 7px;

      .city {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 15px;
        color: var(--black-color);
      }
    }
  }

  .rightSide {
    display: flex;
    align-items: center;
    gap: 22px;

    .messages {
      cursor: pointer;
      &.unread {
        .icon {
          background-color: var(--brand-color);
          background-image: url(../../../../assets/images/dashboard/unread-message.svg);
        }
      }

      h3 {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: var(--black-color);
      }

      .icon {
        width: 40px;
        height: 40px;
        margin-bottom: 4px;
        background-image: url(../../../../assets/images/dashboard/read-message.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #f2f4f8;
        border-radius: 50%;
      }
    }

    .closeIcon {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L1 13' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L13 13' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    padding-left: 15px;
    padding-right: 15px;

    .title {
      padding-right: 0;
    }

    .rightSide {
      gap: 10px;
    }
  }
}
