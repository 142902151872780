.DeclineInterview {
  width: 100%;
  padding-left: 63px;
  padding-right: 50px;

  .header {
    .heading {
      margin-bottom: 22px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 50px;
      color: var(--black-color);
    }

    .question {
      margin-bottom: 15px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
    }
  }
}

@media (max-width: 1024px) {
  .DeclineInterview {
    padding-left: 0;
    padding-right: 0;
    .header {
      .heading {
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 40px;
      }

      .question {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}
