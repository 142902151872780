.SignInPage {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  background-color: var(--secondary-color);

  .formContainer {
    width: 506px;

    // width: 444px;
    //   border: 1px solid red;
  }
}
