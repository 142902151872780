.NoFavorites {
  margin-top: 105px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 31px;

  .text {
    text-align: center;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    color: var(--gray-color);
  }
}

@media (max-width: 1024px) {
  .NoFavorites {
    margin-top: 160px;
    gap: 75px;

    .header {
      display: none;
    }
  }
}
