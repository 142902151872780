:root {
  //colors
  --brand-color: #d52d34;
  --black-color: #242833;
  --secondary-color: #fdf2e4;
  --white-color: white;
  --gray-color: #c1c5d6;

  //fonts
  --title-font: 'Gilroy-SemiBold';
  --subtitle-font: 'Gilroy-Medium';
  --body-font: 'Gilroy-Regular';
  --subbody-font: 'Gilroy-Regular';
}

* {
  box-sizing: border-box;
  scrollbar-color: var(--brand-color) transparent;
  scrollbar-width: thin;
}

html {
  scrollbar-color: var(--brand-color) transparent;
  scrollbar-width: thin;
}

body {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 6px;
}

@font-face {
  font-family: 'National 2 Condensed';
  src: url('./fonts/National2Condensed-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'National 2 Condensed';
  src: url('./fonts/National2Condensed-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'National 2 Condensed';
  src: url('./fonts/National2Condensed-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('./fonts/Sentinel-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('./fonts/Sentinel-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'),
    url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'),
    url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}
