.Assessment {
  display: flex;
  flex-direction: column;
  flex: 1;

  & p {
    width: 654px;
  }

  .assessments {
    margin-top: 38px;
    margin-bottom: 138px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .startAssessmentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
      flex: 1;
      padding-top: 104px;
    }

    .instructionsTitle {
      margin-bottom: 17px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 50px;
      text-align: center;
      color: var(--black-color);
    }

    .instructionsList {
      margin: 0;
      padding: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: var(--black-color);
    }

    .red {
      color: var(--brand-color);
    }
  }
}

@media (max-width: 1024px) {
  .Assessment {
    & p {
      width: 100%;
    }

    .assessments {
      gap: 30px;
      margin-bottom: 48px;
    }

    .startAssessmentContainer {
      > div {
        p {
          margin-bottom: 63px;
          font-size: 32px;
          line-height: 40px;
        }
      }

      .instructionsTitle {
        margin-bottom: 17px;
        font-size: 36px;
        line-height: 53px;
      }

      .instructionsList {
        margin-bottom: 71px;
        padding-left: 17px;
        padding-right: 41px;
      }
    }
  }
}
