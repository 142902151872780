@import '../../mixins';

.LandingPage {
  overflow-x: hidden;
  &.preview {
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 222;
      width: 100%;
      height: 100%;
    }

    .controlHeight {
      height: 838px;
    }

    .fullScreen {
      height: 1130px;
    }
  }

  .controlHeight {
    height: 100vh;
    width: 0;
    position: absolute;
  }

  .whiteButtonContainer {
    width: 444px;
  }

  .fullScreen {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    padding-top: 144px;
    padding-bottom: 157px;
    padding-left: 117px;
    padding-right: 117px;
    background-color: var(--secondary-color);

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 1167px;

      @include onMobile {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 35px;
        padding-bottom: 80px;
      }

      .intro {
        position: relative;
        top: -20px;
        font-family: 'National 2 Condensed';
        font-size: 100px;
        font-weight: 700;
        line-height: 95px;
        text-align: left;
        color: var(--black-color);

        &.safari {
          top: -30px;

          .animationContainer {
            min-height: 100px;
          }
        }

        .animationContainer {
          position: relative;

          overflow: hidden;
          display: inline-block;

          .animatedLine {
            display: inline-block;
            position: relative;
            width: 100%;
            transform: translateY(100%);
            animation: slideUp 0.8s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
            white-space: nowrap;

            &.second {
              animation-delay: 0.7s;
            }

            &.third {
              animation-delay: 1.2s;
            }
          }
        }

        @include onMobile {
          max-width: 290px;
          font-size: 40px;
          line-height: 46px;
        }

        .red {
          font-weight: 800;
          color: var(--brand-color);
        }
      }

      .sanders {
        position: relative;
        // aspect-ratio: 366 / 624;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 451px;
        height: 100%;
        background-image: url(../../assets/images/landing/sanders.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include onMobile {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .LandingPage {
    &.preview {
      .controlHeight {
        height: 926px;
      }

      .fullScreen {
        height: 883px;
      }
    }

    .fullScreen {
      display: flex;
      flex-direction: column;
      height: 100svh;
      padding: 0;
      // background-image: url(../../assets/images/landing/sanders.svg);
      //  background-repeat: no-repeat;
      //  background-position: right 40px center;
      //  background-size: 50%;
    }

    .contentBox {
      flex-direction: column;
      justify-content: unset;
      padding-top: 40px;
      padding-left: 16px;
      padding-right: 16px;
      .textBlock {
        align-items: center;
        padding-top: 20px;
        .workButton {
          margin-bottom: 20px;
        }

        h2 {
          width: 100%;
          margin-bottom: 20px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 46px;
          line-height: 80px;
          color: var(--black-color);
        }

        p {
          margin-bottom: 20px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          color: var(--black-color);
        }
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(15%);
  }
}
