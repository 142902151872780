.ConfirmRescheduleWithButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 44px;
  flex: 1;
  padding-top: 131px;

  .backButton {
    position: absolute;
    z-index: 22;
    top: 247px;
    left: 48px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      width: 560px;
      margin-bottom: 49px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 40px;
      text-align: center;
      color: var(--black-color);
    }
  }
}

@media (max-width: 1024px) {
  .ConfirmRescheduleWithButtons {
    padding-top: 43px;

    .backButton {
      left: 15px;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      .heading {
        width: unset;
      }
    }
  }
}
