.ReplyBox {
  padding-top: 18px;
  h4 {
    margin-bottom: 24px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: right;
    color: #3c3c43;
  }
}

@media (max-width: 1024px) {
  .ReplyBox {
    padding-top: 20px;
    h4 {
      margin-bottom: 31px;
    }
  }
}
