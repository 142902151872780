.ScheduleInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 44px;

  .heading {
    margin-bottom: 8px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 40px;
    text-align: center;
    color: var(--black-color);
  }

  .scheduleDetailsContainer {
    margin-bottom: 30px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .ScheduleInfo {
    gap: 0;
    .heading {
      margin-bottom: 0;
    }

    .scheduleDetailsContainer {
      margin-bottom: 53px;
    }

    .mapContainer {
      margin-bottom: 47px;
    }

    .buttons {
      flex-direction: column;
      gap: 27px;
    }
  }
}
