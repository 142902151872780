.FilterButton {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 71px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  color: var(--gray-color);
  white-space: nowrap;
  background-color: var(--white-color);
  border: 1px solid #f2f4f8;
  border-radius: 50px;
  cursor: pointer;

  &.active {
    border: 1px solid var(--brand-color);
    color: var(--brand-color);

    .count {
      color: var(--white-color);
      background-color: var(--brand-color);
    }
  }

  .count {
    position: absolute;
    top: calc(50% - 15px);
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 30px;
    padding-top: 2px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: var(--gray-color);
    background: #f2f4f8;
    border-radius: 20px;
  }
}

@media (max-width: 1024px) {
  .Filter {
    white-space: nowrap;
  }
}
