.Filters {
  padding-left: 116px;
  padding-right: 116px;

  .filter {
    display: flex;
  }
  .container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 22px;
  }
}

@media (max-width: 1024px) {
  .Filters {
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
}
