@import '../../mixins';

.SelectWithSearch {
  position: relative;
  .selector {
    height: 26px;
    font-family: 'National 2 Condensed';
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: var(--white-color);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #f5e8d8;
    text-transform: uppercase;
    cursor: pointer;

    @include onMobile {
      display: flex;
      align-items: center;
      gap: 20px;
      height: unset;
      padding: 0;
      font-size: 10px;
      line-height: 13px;
    }
  }

  .options {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, 17px);
    font-family: 'National 2 Condensed';
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    color: var(--black-color);

    @include onMobile {
      right: 0;
      left: unset;
      transform: translate(0, 17px);
    }

    .search {
      margin-bottom: 6px;
      .searchInput {
        width: 207px;
        height: 56px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid var(--brand-color);

        &:focus {
          outline: none;
        }
      }
    }

    .optionsListContainer {
      width: 207px;
      padding-right: 7px;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: white;
      border-radius: 15px;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17),
        0px 0px 3px 0px rgba(0, 0, 0, 0.08);

      .optionsList {
        max-width: 200px;
        max-height: 201px;
        margin: 0;

        padding-left: 14px;
        padding-right: 14px;
        list-style: none;
        overflow-y: auto;

        .option {
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
}
