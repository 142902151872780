@import '../../../../mixins';

.JobCard {
  display: flex;
  flex-direction: column;
  width: 289px;
  min-width: 289px;
  height: 217px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 23px;
  background-color: var(--white-color);
  border-radius: 16px;
  overflow: hidden;

  @include onMobile {
    width: 155px;
    min-width: 155px;
    height: 117px;
    padding-top: 7px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 13px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 19px;
    text-transform: uppercase;

    @include onMobile {
      margin-bottom: 5px;
    }

    .text {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;

      @include onMobile {
        line-height: 13px;
      }
    }

    .title {
      margin-bottom: 0;
      margin-right: 10px;
      font-family: 'National 2 Condensed';
      font-size: 21px;
      font-weight: 700;
      line-height: 23.98px;
      text-align: left;
      white-space: nowrap;

      @include onMobile {
        font-size: 11px;
        line-height: 13px;
      }
    }

    .city {
      font-family: 'National 2 Condensed';
      font-size: 21px;
      font-weight: 400;
      line-height: 23.98px;
      text-align: left;

      @include onMobile {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }

  .description {
    height: 86px;
    padding-right: 27px;
    p {
      font-family: 'National 2 Condensed';
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      overflow-wrap: break-word;

      @include onMobile {
        font-size: 6px;
        line-height: 8px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: auto;

    .button {
      width: 158px;
      height: 40px;
      font-family: 'National 2 Condensed';
      font-size: 28px;
      font-weight: 700;
      line-height: 31.98px;
      text-align: center;
      color: var(--white-color);
      text-transform: uppercase;
      background-color: var(--brand-color);
      border-radius: 20px;
      border: none;

      @include onMobile {
        width: 84px;
        height: 19px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
