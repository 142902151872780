.ProfileDropdown {
  position: relative;
  width: 270px;

  .selector {
    display: flex;
    align-items: center;
    width: 270px;
    height: 53px;
    padding-left: 15px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: var(--black-color);
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1.5L7 7.5L1 1.5' stroke='%23242833' stroke-width='2'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-color: var(--white-color);
    border-radius: 8px;
    cursor: pointer;
  }

  .options {
    position: absolute;
    z-index: 2;
    top: 59px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin: 0;
    padding: 15px;
    list-style: none;
    background-color: var(--white-color);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
      0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 8px;

    li {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      cursor: pointer;

      &.active {
        font-size: 24px;
        color: var(--brand-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .ProfileDropdown {
    width: 100%;
    flex: 1;

    .selector {
      width: 100%;
      height: 44px;
      font-size: 18px;
      background-position: right 20px center;
    }

    .options {
      top: 54px;
      li {
        font-size: 18px;
        line-height: 24px;

        &.active {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
