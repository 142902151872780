@import '../../../mixins';

.AutoPlayVideo {
  position: relative;
  z-index: 0;
  top: -45px;
  // height: 601px;
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 100vh;
  margin-bottom: -46px;

  @include onMobile {
    top: -12px;
    // height: 323px;
    margin-bottom: -12px;
    /*   aspect-ratio: unset;
    max-height: unset; */
  }

  &.videoVisible {
    img {
      opacity: 0;
    }
  }

  img {
    transition: opacity 1s;
  }

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
