.ConfirmReschedule {
  display: flex;
  flex-direction: column;
  gap: 44px;
  width: 628px;

  .scheduleDetails {
    .heading {
      margin-bottom: 10px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 41px;
      color: var(--black-color);

      &.red {
        color: var(--brand-color);
      }
    }

    .row {
      display: flex;
      gap: 27px;

      .col {
        flex: 1;
      }
    }
  }
}

@media (max-width: 1024px) {
  .ConfirmReschedule {
    width: 100%;
    gap: 30px;
    .scheduleDetails {
      .heading {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 41px;
        color: var(--gray-color);

        &.red {
          color: #1e1e1e;
        }
      }

      .row {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
