.DocResume {
  h2 {
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--black-color);
  }

  .createdAt {
    position: absolute;
    top: 307px;
    left: 275px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-color);
  }

  .docContainer {
    width: 193px;
    height: 166px;
    border-radius: 12px;

    .doc {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 89px;
      background-image: url(../../../assets/images/doc.svg);
      background-repeat: no-repeat;
      background-size: 77px 67px;
      background-position: top 12px center;
      background-color: var(--white-color);
      border-radius: 11px;
      cursor: pointer;

      &.pdf {
        background-image: url(../../../assets/images/pdf.png);
      }

      button {
        width: 80px;
        height: 24px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--white-color);
        background-color: var(--brand-color);
        border-radius: 7px;
        border: none;
      }

      .fileName {
        width: 123px;
        margin-bottom: 13px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--black-color);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .noResume {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-top: 40px;
      border: 2px solid var(--gray-color);
      border-radius: 12px;

      p {
        width: 81px;
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: var(--black-color);
      }
    }
  }
}
