@import '../../../mixins';

.GivingBack {
  position: relative;
  background-color: var(--secondary-color);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 1003px;
    padding-top: 56px;
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 350px;
    background-color: var(--brand-color);
    background-image: url(../../../assets/images/landing/giving-back.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 938px 343px;
    border-radius: 55px;

    &.volleyball {
      background-image: url(../../../assets/images/landing/giving-back2.png);
      background-size: 1147px 760px;

      @include onMobile {
        background-size: 100%;
      }
    }

    @include onMobile {
      min-height: 476px;
      padding-top: 34px;
      padding-left: 26px;
      padding-right: 26px;
      padding-bottom: 40%;
      background-size: contain;
      background-size: calc(100% - 55px);
      background-position: left 55px bottom 0 right 0;
      border-top-left-radius: 30.6733px 42.7809px;
      border-top-right-radius: 30.6733px 42.7809px;
      border-bottom-right-radius: 30.6733px 42.7809px;
      border-bottom-left-radius: 30.6733px 42.7809px;
    }

    .header {
      position: relative;
      margin-bottom: 202px;

      @include onMobile {
        margin-bottom: 93px;
      }

      .heart {
        position: absolute;
        right: -40px;
        bottom: 35px;
        width: 91px;
        height: 105px;

        @include onMobile {
          right: -20px;
          bottom: 10px;
          width: 33px;
          height: 38px;
        }

        .svg {
          @include onMobile {
            width: 33px;
            height: 38px;
          }

          @keyframes stroke {
            0% {
              stroke-dashoffset: 1000;
            }
            100% {
              stroke-dashoffset: 0;
            }
          }

          &.paused {
            path {
              animation-play-state: paused;
            }
          }

          path {
            fill: transparent;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            stroke-width: 2;
            stroke: white;
            animation: stroke 4s cubic-bezier(0.2, 0.8, 0.8, 0.2) forwards;
            animation-play-state: paused;

            &.pathOne {
              &.playing {
                animation-play-state: running;
              }
            }

            &.pathTwo {
              animation-delay: 250ms;
              &.playing {
                animation-play-state: running;
              }
            }
          }
        }
      }

      .title {
        margin-bottom: 0;
        font-family: 'National 2 Condensed';
        font-size: 80px;
        font-weight: 700;
        line-height: 91px;
        text-align: center;
        color: var(--white-color);
        text-transform: uppercase;

        @include onMobile {
          font-size: 28px;
          line-height: 32px;
        }

        .animation {
          display: inline-block;
          transform: translateY(130%);
          opacity: 0;
          transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);

          &.visible {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }

    .textContainer {
      width: 100%;
      max-width: 1169px;

      @include onMobile {
        max-width: 398px;
      }

      p {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-size: 23px;
        font-weight: 400;
        line-height: 28.66px;
        text-align: left;
        color: var(--white-color);

        @include onMobile {
          font-size: 12px;
          line-height: 14px;
          column-gap: 21px;
        }

        &.textSource {
          column-count: 2;
          column-gap: 71px;
          visibility: hidden;
        }
      }

      .columns {
        position: absolute;
        display: flex;
        gap: 71px;

        @include onMobile {
          column-gap: 21px;
        }

        &.visible {
          p {
            animation: slideIn 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;

            &:first-child {
              animation-delay: 0.2s;
            }
          }
        }

        p {
          opacity: 0;
          transform: translateX(-100%);
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
