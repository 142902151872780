.Dropdown {
  position: relative;
  width: 100%;

  .selector {
    display: flex;
    align-items: center;

    height: 56px;
    padding-top: 2px;
    padding-left: 20px;
    padding-right: 49px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;
    color: var(--brand-color);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.18077 8.82967L0.541993 2.20285C-0.107529 1.27496 0.556286 -8.85647e-07 1.68892 -8.36138e-07L10.3111 -4.59251e-07C11.4437 -4.09742e-07 12.1075 1.27496 11.458 2.20285L6.81923 8.82967C6.42113 9.39839 5.57887 9.39838 5.18077 8.82967Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 19px;
    background-color: #f2f4f8;
    border-radius: 15px;
    cursor: pointer;

    &.open {
      padding-left: 19px;
      background-image: none;
      border: 1px solid var(--brand-color);
    }
  }
  .optionsListContainer {
    position: absolute;
    z-index: 1;
    bottom: -8px;
    transform: translateY(100%);
    width: 100%;
    padding-right: 7px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
      0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 15px;

    .options {
      max-height: 241px;
      margin: 0;
      padding: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      list-style: none;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      li {
        padding-left: 18px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 40px;
        color: var(--black-color);
        cursor: pointer;

        &.active {
          color: var(--brand-color);
        }
      }
    }
  }
}
