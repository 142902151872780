.ViewSample {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 40, 51, 0.5);

  .container {
    position: absolute;
    top: 61px;
    left: calc(50% - 143px);
    width: 286px;
    height: 523px;
    border-radius: 25px;
    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .header {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 116px;
        height: 46px;
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        background-color: var(--white-color);
        backdrop-filter: blur(25px);
        border-radius: 24px;
      }

      button {
        position: absolute;
        top: 12px;
        right: 14px;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }

    .replayButton {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
}
@media (max-width: 1024px) {
  .ViewSample {
    .container {
      top: unset;
      bottom: 108px;
    }
  }
}
