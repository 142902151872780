.Job {
  display: flex;
  flex-direction: column;
  width: 328px;
  height: 300px;
  padding-top: 26px;
  padding-left: 22px;
  padding-right: 22px;
  color: unset;
  text-decoration: unset;
  background-color: var(--white-color);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 20px;
  cursor: pointer;

  &.stretch {
    width: unset;
    min-width: 328px;
  }

  &:hover {
    color: unset;
  }

  .header {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: flex-start;
    margin-bottom: 10px;

    h1 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: var(--black-color);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .favoritesButton {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  .location {
    margin-bottom: 17px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: var(--gray-color);
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
    width: 100%;
    margin-bottom: 17px;
    .jobType,
    .workExperience {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      white-space: nowrap;
    }

    .badge {
      display: flex;
      align-items: center;
      height: 24px;
      padding-left: 6px;
      padding-right: 6px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: var(--white-color);
      background-color: var(--brand-color);
      border-radius: 7px;
      white-space: nowrap;
    }
  }

  .descriptionContainer {
    overflow: hidden;

    .description {
      min-height: 36px;
      margin-bottom: 15px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--black-color);
      // overflow-wrap: break-word;
    }
  }

  .skills {
    display: flex;
    column-gap: 10px;
    row-gap: 5px;
    flex-wrap: wrap;
    //padding-bottom: 26px;

    .skill {
      display: flex;
      align-items: center;
      height: 21px;
      padding-left: 12px;
      padding-right: 12px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--white-color);
      background-color: var(--black-color);
      border-radius: 5px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: 13px;
    padding-bottom: 21px;
    border-top: 1px solid #d9d9d9;

    .leftSide {
      display: flex;
      align-items: center;
      gap: 7px;
      .createdAt {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black-color);
      }

      .messages {
        width: 28px;
        height: 28px;
        background-image: url(../../../assets/images/dashboard/read-message.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--gray-color);
        border-radius: 50%;

        &.unread {
          background-image: url(../../../assets/images/dashboard/unread-message.svg);
          background-color: var(--brand-color);
        }
      }
    }
    .offerButton {
      width: 119px;
      min-height: 28px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #fef2e1;
      background-color: #0acf83;
      border-radius: 7px;
      border: none;

      &.red {
        background-color: var(--brand-color);
      }
    }

    .status {
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: 14px;
      padding-right: 14px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      background-color: #f2f4f8;
      border-radius: 7px;
    }
  }
}

@media (max-width: 1024px) {
  .Job {
    width: 100%;
  }
}
