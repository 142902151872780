.Rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .label {
    margin-bottom: 16px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #3c3c43;
  }

  .stars {
    display: flex;
  }
}
