.dialog {
  max-width: 335px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
}

.AlertModal {
  border-radius: 20px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 330px;
    padding-bottom: 22px;
    background-color: var(--white-color);
    border-radius: 20px;

    header {
      width: 100%;
      margin-bottom: 11px;
      padding-top: 22px;
      padding-right: 20px;
      padding-left: 20px;

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        margin-left: auto;
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='16' fill='%23242833'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.9144 15.0247L19.9196 11.0195C20.2125 10.7266 20.6874 10.7266 20.9803 11.0195C21.2732 11.3124 21.2732 11.7872 20.9803 12.0801L16.9751 16.0853L20.9803 20.0905C21.2732 20.3834 21.2732 20.8583 20.9803 21.1512C20.6874 21.4441 20.2125 21.4441 19.9196 21.1512L15.9144 17.146L11.9092 21.1512C11.6163 21.4441 11.1415 21.4441 10.8486 21.1512C10.5557 20.8583 10.5557 20.3834 10.8486 20.0905L14.8538 16.0853L10.8486 12.0801C10.5557 11.7872 10.5557 11.3124 10.8486 11.0195C11.1415 10.7266 11.6163 10.7266 11.9092 11.0195L15.9144 15.0247Z' fill='white'/%3E%3C/svg%3E%0A");
        cursor: pointer;
      }
    }

    .icon {
      margin-bottom: 15px;
    }

    h1 {
      margin: 0;
      margin-bottom: 8px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
    }

    p {
      width: 239px;
      margin-bottom: 39px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: var(--black-color);
      // opacity: 0.7;
    }

    .buttons {
      display: flex;
      gap: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .dialog {
    margin-left: auto;
    margin-right: auto;
  }
}
