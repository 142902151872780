.Textarea {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.withInfo {
    .label {
      margin-bottom: 2px;
    }
  }

  .label {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #3c3c43;
  }

  .info {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--black-color);
  }

  textarea {
    padding: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border: none;
    resize: none;
    border-radius: 16px;

    &::placeholder {
      color: var(--gray-color);
    }

    &:focus {
      outline: none;
    }
  }



  .charCount {
    margin-top: 4px;
    height: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    margin-left: auto;
    color: var(--gray-color);
  }
        .textAreaInfo {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        
        .typingIndicator {
          margin-top: 4px;
          height: 20px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
            margin-right: auto;
          color: var(--gray-color);
        }
}
