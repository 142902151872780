.Languages {
  position: relative;
  z-index: 1;
  font-family: 'National 2 Condensed';
  font-size: 28px;

  .activeLanguage {
    color: #d52d34;
    text-decoration-line: underline;
    line-height: 40px;
    font-weight: 500;
    cursor: pointer;
  }

  .list {
    position: absolute;
    top: calc(100% + 9px);
    width: 76px;
    padding: 14px 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17),
      0px 0px 3px 0px rgba(0, 0, 0, 0.08);
    list-style: none;

    .listItem {
      display: flex;
      gap: 15px;
      align-items: center;
      cursor: pointer;

      &.selected {
        color: #ff2d55;
      }
    }

    .languageFlag {
      width: 20px;
      height: 14px;
    }
  }
}
