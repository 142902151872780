.Avatar {
  width: 135px;
  height: 135px;

  .preview {
    position: relative;
    .deleteButton {
      position: absolute;
      right: 0px;
      width: 30px;
      height: 30px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00025 3.99533L8.7878 0.207778C9.06483 -0.0692594 9.514 -0.0692595 9.79104 0.207778C10.0681 0.484816 10.0681 0.933983 9.79104 1.21102L6.00349 4.99857L9.79333 8.78841C10.0704 9.06545 10.0704 9.51461 9.79333 9.79165C9.51629 10.0687 9.06713 10.0687 8.79009 9.79165L5.00025 6.00181L1.21102 9.79104C0.933983 10.0681 0.484816 10.0681 0.207778 9.79104C-0.0692595 9.514 -0.0692594 9.06483 0.207778 8.7878L3.99701 4.99857L0.210073 1.21163C-0.0669649 0.934597 -0.0669649 0.48543 0.210073 0.208392C0.487111 -0.0686454 0.936278 -0.0686453 1.21332 0.208392L5.00025 3.99533Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: #ff2d55;
      border-radius: 50%;
      border: none;
    }

    img {
      width: 135px;
      height: 135px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .uploader {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='45' viewBox='0 0 46 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.895996' width='44.2079' height='44.2079' rx='22.1039' fill='%23F1F4F8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.994 21.0979V16.8278C21.994 16.4116 22.3314 16.0742 22.7476 16.0742C23.1637 16.0742 23.5011 16.4116 23.5011 16.8278V21.0979H27.773C28.1891 21.0979 28.5265 21.4353 28.5265 21.8514C28.5265 22.2676 28.1891 22.605 27.773 22.605H23.5011V26.875C23.5011 27.2912 23.1637 27.6285 22.7476 27.6285C22.3314 27.6285 21.994 27.2912 21.994 26.875V22.605H17.7257C17.3095 22.605 16.9722 22.2676 16.9722 21.8514C16.9722 21.4353 17.3095 21.0979 17.7257 21.0979H21.994Z' fill='%23C1C5D6'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: top 16px center;
    border: 2px dashed var(--gray-color);
    border-radius: 50%;
    cursor: pointer;

    input {
      display: none;
    }

    span {
      width: 90px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: var(--gray-color);
    }
  }
}

@media (max-width: 1024px) {
  .Avatar {
    min-width: 86px;
    max-width: 86px;
    height: 86px;

    .preview {
      .deleteButton {
        width: 25px;
        height: 25px;
      }

      img {
        width: 86px;
        height: 86px;
      }
    }

    .uploader {
      background-size: 30%;
      background-position: top 6px center;

      span {
        font-size: 16px;
        line-height: 14px;
      }
    }
  }
}
