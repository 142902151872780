@import '../../../mixins';

.Footer {
  padding-top: 95px;
  padding-bottom: 51px;
  background-color: var(--secondary-color);

  &.nofooter {
    display: none;
  }

  .backgroundContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    .background {
      width: 515px;

      @include onMobile {
        width: 227px;
      }
    }
  }

  .copyright {
    height: 55px;
    font-family: 'National 2 Condensed';
    font-size: 24px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: var(--black-color);

    @include onMobile {
      height: 25px;
      font-size: 10px;
      line-height: 23px;
    }
  }

  .socButtons {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 15px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 30px;
      color: var(--brand-color);
      text-transform: uppercase;

      @include onMobile {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 30px;
      }
    }

    .list {
      display: flex;
      align-items: center;
      gap: 30px;
      margin: 0;
      padding: 0;
      list-style: none;

      @include onMobile {
        gap: 5px;
      }

      img {
        transform: scale(1.5);

        @include onMobile {
          transform: scale(0.7);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Footer {
    padding-top: 45px;
    padding-bottom: 58px;

    .socButtons {
      margin-bottom: 0;
    }
  }
}
