@import '../../../mixins';

.OurPeoplePromise {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 185px;
  background-color: var(--secondary-color);

  @include onMobile {
    padding-top: 40px;
    padding-left: 33px;
    padding-right: 33px;
    padding-bottom: 103px;
  }

  &:not(.inView) {
    * {
      &:not(.animated) {
        animation: none !important;
      }
    }
  }

  .title {
    margin-bottom: 45px;
    font-family: 'National 2 Condensed';
    font-size: 91px;
    font-weight: 700;
    line-height: 103.92px;
    text-align: center;
    color: var(--brand-color);
    text-transform: uppercase;

    @include onMobile {
      margin-bottom: 26px;
      font-size: 30px;
      line-height: 34px;
    }

    .animated {
      display: inline-block;
      transform: translateY(80%);
      opacity: 0;

      &.inView {
        animation: slideUp 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
      }
    }
  }

  .logo {
    margin-bottom: 54px;

    @include onMobile {
      width: 73px;
      margin-bottom: 35px;
    }
  }

  .text {
    margin: 0;
    width: 333px;
    font-family: 'National 2 Condensed';
    font-size: 24px;
    font-weight: 325;
    line-height: 28.8px;
    text-align: center;
    white-space: pre-line;
    color: var(--black-color);

    @include onMobile {
      width: 114px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .opacityIn {
    opacity: 0;
    animation: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;

    &.second {
      animation-delay: 1s;
    }

    &.third {
      animation-delay: 2s;
    }
  }

  .promises {
    position: relative;
    width: 642px;
    height: 578px;
    margin-bottom: 15px;
    //  background-image: url(../../../assets/images/landing/promise.svg),
    //    url(../../../assets/images/landing/promise2.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: center, center bottom 78px;

    /* @include onMobile {
      width: 100%;
      max-width: 307px;
      aspect-ratio: 307 / 276;
      height: auto;
      background-image: url(../../../assets/images/landing/promise.svg),
        url(../../../assets/images/landing/promise2-mobile.svg);
      background-size: contain, 108px;
      background-position: center, center bottom 62px;
    } */

    @include onMobile {
      width: 306px;
      height: 276px;
    }

    .firstArrow {
      position: absolute;
      top: 128px;
      right: 70px;

      @include onMobile {
        transform: scale(0.5) rotate(3deg);
        top: 10px;
        right: -10px;
      }
    }

    .secondArrow {
      position: absolute;
      bottom: -64px;
      left: 268px;
      transform-origin: center;
      transform: rotate(128deg) scaleX(1.2);

      @include onMobile {
        top: 0;
        left: 35px;
        bottom: -170px;
        transform: rotate(124deg) scale(0.5);
      }
    }

    .thirdArrow {
      position: absolute;
      top: 143px;
      left: 69px;
      transform: scale(-1) rotate(63deg);

      @include onMobile {
        transform: scale(-0.5) rotate(63deg);
        top: 15px;
        left: -5px;
      }
    }

    .kendinin {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(calc(-50% - 13px));

      @include onMobile {
        transform: translateX(calc(-50%)) !important;
        img {
          width: 82px;
          height: 72px;
        }
      }
    }

    .fark {
      position: absolute;
      right: 0;
      bottom: 58px;
      animation-delay: 1s !important;

      @include onMobile {
        bottom: 28px;
        width: 67px;
        height: 67px;
      }
    }

    .tadini {
      position: absolute;
      left: 0;
      bottom: 54px;
      animation-delay: 2s !important;

      @include onMobile {
        bottom: 28px;
        width: 79px;
        height: 70px;
      }
    }

    .bizBoyleyiz {
      position: absolute;
      left: 50%;
      bottom: 170px;
      transform: translateX(-50%);

      @include onMobile {
        width: 109px;
        bottom: 62px;
      }
    }

    .zoomIn {
      opacity: 0;
      animation: zoomInAndOpacity 0.7s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    }

    .text {
      position: absolute;
      top: 190px;
      left: 50%;
      width: 301px;
      transform: translateX(-50%);

      @include onMobile {
        top: unset;
        top: 35%;
        width: 114px;
      }
    }
  }

  .textCols {
    display: flex;
    gap: 239px;

    @include onMobile {
      width: 100%;
      max-width: 373px;
      gap: 20px;
      justify-content: space-between;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(80%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoomInAndOpacity {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
