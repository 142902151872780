.UploadInProgress {
  height: 100%;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 157px;

  .progressBar {
    position: absolute;
    top: 79px;
    left: 38px;
    width: calc(100% - 38px - 38px);
    height: 10px;
    background-color: #f2f4f8;
    border-radius: 50px;

    .progress {
      width: 0;
      height: 100%;
      background-color: var(--brand-color);
      border-radius: 50px;
      transition: all 0.1s;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    h1 {
      margin: 0;
      margin-bottom: 27px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 46px;
      line-height: 53px;
      text-align: center;
      color: var(--black-color);
    }

    svg {
      margin-bottom: -15px;
    }

    .internetSpeed {
      margin-bottom: 42px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;

      span {
        margin-left: 5px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #0acf83;
      }
    }

    .info {
      width: 353px;
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: var(--black-color);
    }
  }
}
