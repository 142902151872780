.PolicyPage {
  padding-top: 44px;
  padding-bottom: 44px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: var(--secondary-color);

  .header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 31px;
    padding-left: 5px;

    .backIcon {
      position: relative;
      top: -3px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../assets/images/dashboard/back-arrow.svg);
      background-position: center;
      cursor: pointer;
    }

    h1 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
      color: var(--black-color);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 15px;

    section {
      h2 {
        margin: 0;
        margin-bottom: 15px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
      }

      p {
        margin: 0;
        margin-bottom: 12px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
      }

      ul,
      ol {
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .PolicyPage {
    padding-top: 33px;
    padding-left: 18px;
    padding-right: 18px;
    .header {
      margin-bottom: 21px;

      h1 {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 40px;
        color: var(--black-color);
      }
    }

    .content {
      gap: 20px;
      section {
        h2 {
          margin-bottom: 20px;
          font-family: 'Gilroy-Bold';
          font-size: 14px;
          line-height: 24px;
        }

        p {
          margin-bottom: 20px;
          font-family: 'Gilroy-Regular';
          font-size: 14px;
          line-height: 24px;
        }

        ul,
        ol {
          font-family: 'Gilroy-Regular';
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
