.VideoResume {
  .createdAt {
    position: absolute;
    left: 30px;
    bottom: 16px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-color);
  }

  h2 {
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--black-color);
  }

  .container {
    width: 202px;
    height: 360px;

    .videoContainer {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 11px;
      overflow: hidden;
      cursor: pointer;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .playButton {
        position: absolute;
        left: calc(50% - 25px);
        bottom: 68px;
        z-index: 1;
        width: 50px;
        height: 50px;
        padding: 0;
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0001 33.0686C25.8761 33.0686 33.0716 25.8732 33.0716 16.9972C33.0716 8.1212 25.8761 0.925781 17.0001 0.925781C8.12413 0.925781 0.928711 8.1212 0.928711 16.9972C0.928711 25.8732 8.12413 33.0686 17.0001 33.0686ZM13.4258 9.05907L26.1409 16.1231C26.8268 16.5041 26.8267 17.4904 26.1409 17.8714L13.4258 24.9354C12.6259 25.3797 11.643 24.8013 11.643 23.8864V10.1081C11.643 9.19307 12.6259 8.61471 13.4258 9.05907Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--brand-color);
        border-radius: 50%;
        border: none;
      }
    }

    .noResume {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-top: 40px;
      border: 2px solid var(--gray-color);
      border-radius: 12px;

      p {
        width: 81px;
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: var(--black-color);
      }
    }
  }
}
