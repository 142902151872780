.VideoUploadSuccess {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-bottom: 59px;
  padding-left: 46px;
  padding-right: 46px;

  img {
    width: 250px;
    margin-bottom: 51px;
  }

  h1 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 53px;
    text-align: center;
    color: var(--black-color);
  }

  .details {
    width: 317px;
    margin: 0;
    margin-bottom: 60px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: var(--black-color);
  }

  .info {
    width: 336px;
    margin: 0;
    margin-bottom: 83px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--black-color);
  }

  .buttonContainer {
    width: 336px;
  }
}
