.UnderMaintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 376px;
  height: calc(100vh - 88px);
  min-height: 600px;
  background: rgba(253, 242, 228, 1);

  .container {
    display: flex;
    flex-direction: column;
    gap: 26px;

    .illustration {
      display: flex;
      justify-content: center;
      img {
        width: 247px;
        height: 200px;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1,
      span {
        font-family: 'National 2 Condensed';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1024px) {
  .UnderMaintenance {
    height: calc(100vh - 45px);

    .container {
      .illustration {
        img {
          width: 197px;
          height: 160px;
        }
      }

      .message {
        h1,
        span {
          font-size: 28px;
        }

        h1 {
          font-weight: 500;
          line-height: 31px;
        }

        h1 {
          width: 220px;
        }
      }
    }
  }
}
