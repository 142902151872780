.Offered {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (max-width: 1024px) {
  .Offered {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 48px;
  }
}
