.ApplyJobPage {
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 76px;
  background-color: var(--secondary-color);

  .header {
    position: relative;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 73px;

    p {
      margin: 0;
    }

    h1 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 120px;
      line-height: 120px;
      text-align: center;
      color: var(--brand-color);
      text-transform: uppercase;
    }

    .info {
      margin-bottom: 64px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: var(--black-color);
    }

    .cards {
      position: relative;
      display: flex;
      gap: 20px;
      margin-bottom: 147px;

      .or {
        display: none;
      }

      .shadow {
        position: absolute;
        z-index: 0;
        top: 246px;
        left: 24px;
        width: 394px;
        height: 64px;
        background-color: rgba(0, 0, 0, 0.2);
        filter: blur(25px);
        border-radius: 10px;
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 444px;
        height: 279px;
        background-color: var(--white-color);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
          0px 2px 3px rgba(0, 0, 0, 0.168);
        border-radius: 15px;
        cursor: pointer;

        &.recommended {
          position: relative;
          z-index: 1;
          box-shadow: none;

          &::after {
            content: 'Recommended';
            position: absolute;
            top: 18px;
            left: 42px;
            height: 31px;
            padding-left: 19px;
            padding-right: 19px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 36px;
            color: var(--white-color);
            background-color: var(--black-color);
            border-radius: 5px;
          }
        }

        h2 {
          margin: 0;
          margin-bottom: 10px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 46px;
          text-align: center;
          color: var(--brand-color);
        }

        p {
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          color: var(--black-color);
        }
      }
    }
    .savedAccount {
      position: relative;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateX(-100%) translateY(-53%);
        width: 25px;
        height: 25px;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5001 21.6993C12.2947 21.7011 12.0911 21.6614 11.9014 21.5825C11.7118 21.5037 11.5401 21.3873 11.3966 21.2403L3.28133 13.1251C2.71806 12.5633 2.2753 11.8926 1.98011 11.1538C1.68492 10.4151 1.54352 9.62392 1.56454 8.82868C1.58557 8.03343 1.76858 7.25084 2.10238 6.52874C2.43619 5.80664 2.91378 5.16021 3.50594 4.62899C4.07422 4.11842 4.74038 3.72875 5.46394 3.48364C6.1875 3.23854 6.95334 3.14313 7.71492 3.20321C9.24596 3.30267 10.6876 3.95922 11.7677 5.04891L12.5001 5.77157L13.4376 4.84383C13.9994 4.28056 14.6701 3.8378 15.4088 3.54261C16.1476 3.24742 16.9387 3.10602 17.734 3.12704C18.5292 3.14807 19.3118 3.33108 20.0339 3.66488C20.756 3.99869 21.4025 4.47628 21.9337 5.06844C22.4442 5.63672 22.8339 6.30288 23.079 7.02644C23.3241 7.75 23.4195 8.51584 23.3595 9.27743C23.26 10.8085 22.6034 12.2501 21.5138 13.3302L13.6036 21.2403C13.4601 21.3873 13.2884 21.5037 13.0987 21.5825C12.9091 21.6614 12.7055 21.7011 12.5001 21.6993ZM7.3243 4.75594C6.30529 4.74566 5.31811 5.11064 4.55086 5.78133C4.11591 6.17147 3.7651 6.64621 3.51987 7.17654C3.27464 7.70687 3.14015 8.28164 3.12461 8.86571C3.10907 9.44979 3.21281 10.0309 3.4295 10.5735C3.64618 11.1161 3.97126 11.6089 4.38485 12.0216L13.0567 20.6934L12.5001 20.1368L20.4102 12.2266C22.1192 10.5274 22.2755 7.78328 20.7813 6.11336C20.3912 5.67841 19.9165 5.3276 19.3861 5.08237C18.8558 4.83714 18.281 4.70265 17.697 4.68711C17.1129 4.67157 16.5318 4.77531 15.9891 4.992C15.4465 5.20868 14.9538 5.53376 14.5411 5.94735L13.0567 7.43172C12.9086 7.57843 12.7086 7.66073 12.5001 7.66073C12.2916 7.66073 12.0916 7.57843 11.9434 7.43172L10.6641 6.15243C10.2265 5.71161 9.70629 5.36139 9.13321 5.12177C8.56014 4.88215 7.94545 4.75784 7.3243 4.75594Z' fill='white'/%3E%3C/svg%3E%0A");
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateX(100%) translateY(-53%);
        width: 25px;
        height: 25px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 13V20.2C4 20.6774 4.21071 21.1352 4.58579 21.4728C4.96086 21.8104 5.46957 22 6 22H18C18.5304 22 19.0391 21.8104 19.4142 21.4728C19.7893 21.1352 20 20.6774 20 20.2V13' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 6.5L12 2.5L8 6.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 3V15' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      span {
        color: var(--brand-color);
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .ApplyJobPage {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 22px;
    .header {
      button {
        display: none;
      }
    }

    .container {
      padding-top: 18px;

      h1 {
        margin-bottom: 10px;
        font-size: 64px;
        line-height: 59px;
      }

      .info {
        margin-bottom: 52px;
        font-size: 24px;
      }

      .cards {
        flex-direction: column;
        gap: 45px;
        width: 100%;
        margin-bottom: 25px;

        .shadow {
          width: 80%;
          top: 136px;
        }

        .or {
          display: flex;
          justify-content: center;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          color: var(--black-color);
        }

        .card {
          width: 100%;
          height: unset;
          padding-top: 76px;
          padding-bottom: 57px;

          h2 {
            margin-bottom: 0;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
          }

          p {
            font-size: 22px;
          }
        }
      }
      .savedAccount {
        width: 210px;
        text-align: center;
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
