.ContactOrShareFeedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .backButton {
    position: absolute;
    top: 255px;
    left: 53px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }

  .contactUsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 48px;
  }
}

@media (max-width: 1024px) {
  .ContactOrShareFeedback {
    position: relative;
    padding-left: 16px;
    padding-right: 16px;

    .backButton {
      top: 47px;
      left: 15px;
    }
  }
}
