.Video {
  position: relative;
  width: 321px;
  min-width: 321px;
  height: 723px;
  border: 3px solid var(--brand-color);
  border-radius: 20px;
  overflow: hidden;

  canvas {
    position: absolute;
    top: -5000px;
  }

  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1);
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 48px;

    .frame {
      position: absolute;
      top: 108px;
      left: calc(50% - 148px);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 296px;
      height: 348px;
      background-image: url("data:image/svg+xml,%3Csvg width='296' height='348' viewBox='0 0 296 348' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M212.166 1H267.054C279.022 1 285.006 1 289.091 4.22025C290.017 4.95044 290.854 5.78734 291.584 6.71359C294.804 10.7985 294.804 16.7823 294.804 28.75V99.1194' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M83.834 1H28.9456C16.9779 1 10.9941 1 6.90919 4.22025C5.98294 4.95044 5.14604 5.78734 4.41585 6.71359C1.1956 10.7985 1.1956 16.7823 1.1956 28.75V99.1194' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M83.834 347H28.9456C16.9779 347 10.9941 347 6.90919 343.78C5.98294 343.05 5.14604 342.213 4.41585 341.286C1.1956 337.202 1.1956 331.218 1.1956 319.25V248.881' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M212.166 347H267.054C279.022 347 285.006 347 289.091 343.78C290.017 343.05 290.854 342.213 291.584 341.286C294.804 337.202 294.804 331.218 294.804 319.25V248.881' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");

      .countdownTimer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 164px;
        height: 164px;
        padding-top: 10px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 96px;
        line-height: 30px;
        color: var(--white-color);
        background-color: rgba(36, 40, 51, 0.5);
        border-radius: 50%;
      }

      .positionFace {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 258px;
        height: 271px;
        background-color: rgba(36, 40, 51, 0.5);
        backdrop-filter: blur(25px);
        border-radius: 20px;
        overflow: hidden;

        p {
          width: 171px;
          margin: 0;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          color: var(--white-color);
          text-align: center;
        }
      }
    }

    .buttons {
      position: absolute;
      left: calc(50% - 85px);
      display: flex;
      align-items: center;
      width: 170px;
      height: 70px;

      .flipCameraButton {
        position: absolute;
        left: 0;
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }

    .readyButtons {
      position: absolute;
      z-index: 1000;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 23px;

      button {
        width: 87px;
        height: 40px;
        border-radius: 50px;
        border: none;

        &.retakeButton {
          padding-top: 3px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: var(--white-color);
          background-color: transparent;
          border: 1.5px solid var(--white-color);
        }

        &.useButton {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          color: var(--brand-color);
          text-transform: uppercase;
          background-color: var(--white-color);

          span {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    min-width: unset;
    height: 100dvh;
    border-radius: 0;
    border: none;
    background-color: #fcf2e4;

    .overlay {
      .frame {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
