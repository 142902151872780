.RescheduleWithButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 13px;

  .heading {
    margin-bottom: 35px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 40px;
    text-align: center;
    color: var(--black-color);
  }

  .buttons {
    display: flex;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .RescheduleWithButtons {
    padding-top: 0;

    .buttons {
      flex-direction: column;
      width: 100%;
      margin-top: 59px;
    }
  }
}
