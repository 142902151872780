@import '../../../mixins';

.HowWeLead {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--secondary-color);

  @include onMobile {
    padding-top: 73px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
  }

  .red {
    color: var(--brand-color);
  }

  .title {
    margin-bottom: 20px;
    font-family: 'National 2 Condensed';
    font-size: 80px;
    font-weight: 700;
    line-height: 91px;
    text-align: center;
    color: var(--black-color);
    text-transform: uppercase;

    @include onMobile {
      margin-bottom: 31px;
      font-size: 30px;
      line-height: 34px;
    }
  }

  .text {
    margin-bottom: 40px;
    font-family: 'National 2 Condensed';
    font-size: 39px;
    font-weight: 700;
    line-height: 48.59px;
    text-align: center;
    color: var(--black-color);
    text-transform: uppercase;
    white-space: pre-line;

    @include onMobile {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 20px;
    }

    &.secondText {
      @include onMobile {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  .image {
    width: 428px;
    margin-bottom: 20px;

    @include onMobile {
      width: 176px;
      max-width: 324px;
    }
  }

  .smallText {
    margin: 0;
    width: 663px;
    font-family: 'National 2 Condensed';
    font-size: 35px;
    font-weight: 400;
    line-height: 39.97px;
    text-align: center;
    color: var(--black-color);

    @include onMobile {
      width: 100%;
      max-width: 304px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
