@import '../../../../mixins';

.Video {
  position: relative;
  width: 100%;
  max-width: 473px;
  aspect-ratio: 473 / 600;
  background-color: var(--secondary-color);
  border-radius: 33px;
  overflow: hidden;
  user-select: none;

  @include onMobile {
    max-width: 174px;
    aspect-ratio: 174 / 222;
    border-radius: 6px;
  }

  &:hover {
    .playButton {
      opacity: 1;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--secondary-color);
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 33px;
    overflow: hidden;

    @include onMobile {
      border-radius: 6px;
    }
  }

  .text {
    position: absolute;
    bottom: 54px;
    left: 30px;
    z-index: 2;

    @include onMobile {
      left: 15px;
      bottom: 15px;
    }

    .title {
      margin-bottom: 0;
      font-family: 'National 2 Condensed';
      font-size: 50px;
      font-weight: 500;
      line-height: 46px;
      text-align: left;
      color: var(--brand-color);

      @include onMobile {
        position: relative;
        top: 10px;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .subTitle {
      font-family: 'National 2 Condensed';
      font-size: 34px;
      font-weight: 500;
      line-height: 40px;
      text-align: left;
      color: var(--black-color);

      @include onMobile {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .playButton {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;

    @include onMobile {
      opacity: 1;
    }
  }
}
