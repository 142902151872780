.Title {
  min-height: 41px;
  margin: 0;
  margin-bottom: 20px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 41px;
  text-align: center;
  color: var(--black-color);
}
