.AdditionalInfoForm {
  h2 {
    margin-bottom: 40px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 30px;
    color: var(--black-color);
  }

  .dropdowns {
    display: flex;
    flex-direction: column;
    gap: 44px;
    .row {
      display: flex;
      gap: 33px;
      .col {
        flex: 1;
      }
    }
  }
}

@media (max-width: 1024px) {
  .AdditionalInfoForm {
    h2 {
      margin-bottom: 23px;
      font-size: 24px;
      line-height: 37px;
    }

    .dropdowns {
      gap: 48px;
      .row {
        flex-direction: column;
        gap: 48px;
        .col {
          flex: 1;
        }
      }
    }
  }
}
