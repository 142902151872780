.JobsAppliedStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-width: 328px;
  height: 300px;
  padding-top: 17px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--white-color);
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  h2 {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: var(--black-color);
  }

  .legend {
    display: flex;
    gap: 48px;
    margin-bottom: 17px;

    .legendElement {
      display: flex;
      align-items: center;

      .color {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 4px;
      }

      .value {
        margin-right: 12px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
      }

      .label {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .viewAllButton {
    width: 126px;
    height: 40px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    color: var(--brand-color);
    white-space: nowrap;
  }
}

@media (max-width: 1024px) {
  .JobsAppliedStatus {
    min-width: 337px;
  }
}
