.options {
  // position: absolute;
  position: relative;
  //  top: 0;
  top: -165px;
  z-index: 1;
  width: 100%;
  margin-bottom: -165px;
  background-color: var(--white-color);
  border: 1px solid #f2f4f8;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .header {
    display: flex;
    justify-content: center;
    height: 64px;
    padding-top: 22px;
    background-image: url(../../../../assets/images/location.svg),
      url(../../../../assets/images/arrow-up.svg);
    background-repeat: no-repeat;
    background-position: left 16px top 19px, right 16px top 28px;
    background-color: var(--white-color);
    border-radius: 15px;

    .title {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .searchInputContainer {
    padding-left: 22px;
    padding-right: 22px;

    .searchInput {
      width: 100%;
      height: 64px;
      padding-left: 48px;
      padding-right: 20px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.86047 0C13.3654 0 17.0297 3.66431 17.0297 8.1692C17.0297 10.2946 16.214 12.2331 14.8793 13.6879L17.5057 16.3088C17.7515 16.5546 17.7523 16.9522 17.5065 17.198C17.384 17.3222 17.2221 17.3834 17.061 17.3834C16.9008 17.3834 16.7397 17.3222 16.6164 17.1997L13.9583 14.549C12.5601 15.6688 10.7872 16.3392 8.86047 16.3392C4.35558 16.3392 0.69043 12.6741 0.69043 8.1692C0.69043 3.66431 4.35558 0 8.86047 0ZM8.86047 1.25835C5.04935 1.25835 1.94878 4.35808 1.94878 8.1692C1.94878 11.9803 5.04935 15.0809 8.86047 15.0809C12.6707 15.0809 15.7713 11.9803 15.7713 8.1692C15.7713 4.35808 12.6707 1.25835 8.86047 1.25835Z' fill='%23C1C5D6'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: left 11px center;
      background-color: #f2f4f8;
      border-radius: 15px;
      border: none;

      &::placeholder {
        color: var(--gray-color);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.options {
  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-height: 263px;
    margin: 0;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 10px;
    padding-bottom: 25px;
    list-style: none;
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 64px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      /* or 100% */

      display: flex;
      align-items: center;
      text-align: center;

      color: var(--gray-color);

      border: 1px solid #f2f4f8;
      border-radius: 15px;

      &.active {
        background-color: var(--black-color);
        border: 1px solid #f2f4f8;
        border-radius: 15px;
        color: var(--white-color);
      }
    }
  }
}

.currentOption {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--white-color);
  background-color: var(--black-color);
  border: 1px solid #f2f4f8;
  border-radius: 15px;
}

@media (max-width: 1024px) {
  .currentOption {
    height: 48px;
    font-size: 16px;
  }

  .options {
    ul {
      li {
        min-height: 48px;
        height: 48px;
        font-size: 16px;
      }
    }
    .header {
      .title {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: var(--black-color);
      }
    }

    .searchInputContainer {
      .searchInput {
        height: 48px;
      }
    }
  }
}
