@import '../../../../mixins';

.Event {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 29px;
  height: 29px;
  // background-color: var(--brand-color);
  // border-radius: 50%;

  @include onMobile {
    width: 13px;
    height: 13px;
  }

  .dot {
    width: 100%;
    height: 100%;
    background-color: var(--brand-color);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 6s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.visible {
      transform: scale(1);
    }
  }

  .bucket {
    position: absolute;
    top: -51px;
    left: 50%;
    transform-origin: center bottom;
    transform: translate(-50%, -100%) scale(0);
    transition-property: all;
    transition-duration: 5.5s;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

    @include onMobile {
      top: -5px;
    }

    &.inView {
      transform: translate(-50%, -100%) scale(1);
    }
  }

  .year {
    position: absolute;
    left: 50%;
    bottom: -26px;
    font-family: 'National 2 Condensed';
    font-size: 47px;
    font-weight: 700;
    line-height: 53px;
    text-align: center;
    color: var(--black-color);
    transform: translate(-50%, 100%) scale(0);
    transition: transform 5.7s cubic-bezier(0.25, 0.8, 0.25, 1);

    @include onMobile {
      font-size: 26px;
      line-height: 30px;
    }

    &.visible {
      transform: translate(-50%, 100%) scale(1);
    }
  }

  .textContainer {
    position: absolute;
    left: 50%;
    top: 117px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 1.5s;

    @include onMobile {
      top: 93px;
    }

    &.visible {
      opacity: 1;
    }

    .header {
      margin-bottom: 29px;
      width: 270px;
      min-height: 90px;
      font-family: 'National 2 Condensed';
      font-size: 26px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      color: var(--black-color);

      .title {
        color: var(--brand-color);
      }

      @include onMobile {
        width: 210px;
        font-size: 23px;
        line-height: 26px;
      }
    }

    .text {
      margin: 0;
      width: 310px;
      font-family: 'National 2 Condensed';
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: var(--black-color);

      @include onMobile {
        width: 268;
        font-size: 17px;
        line-height: 19px;
      }
    }
  }
}
