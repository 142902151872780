.dialog {
  max-width: 908px;
}

.modalContent {
  border-radius: 20px;
  border: none;
}

.ApplicationSuccessModal {
  border-radius: 20px;
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 111px;
    padding-bottom: 69px;
    background-color: var(--secondary-color);
    border-radius: 20px;
    overflow: hidden;

    .closeIcon {
      position: absolute;
      top: 29px;
      right: 24px;
      display: block;
      width: 14px;
      height: 14px;
      margin-left: auto;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L1 13' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L13 13' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      cursor: pointer;
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 501px;
      height: 182px;
      margin-bottom: 105px;

      h1 {
        margin: 0;
        margin-bottom: 20px;
        font-family: 'National 2 Condensed';
        font-size: 42px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: var(--black-color);
      }
    }

    .text {
      margin-bottom: 135px;
      p {
        width: 676px;
        margin: 0;
        font-family: 'National 2 Condensed';
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: var(--black-color);
      }
    }

    .buttons {
      display: flex;
      gap: 16px;
      margin-top: auto;
    }
  }
}

@media (max-width: 1024px) {
  .dialog {
    margin-left: 16px;
    margin-right: 16px;
  }

  .modalContent {
    border-radius: 16px;
  }

  .ApplicationSuccessModal {
    border-radius: 16px;
    .content {
      padding-top: 115px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 41px;
      .header {
        width: unset;
        height: unset;
        margin-bottom: 99px;
      }

      .text {
        p {
          width: unset;
        }
      }
    }
  }
}
