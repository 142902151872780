.InfoField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  height: 54px;
  padding-top: 2px;
  padding-left: 52px;
  padding-right: 22px;
  background-repeat: no-repeat;
  background-position: 20px center;
  background-color: #f2f4f8;
  border-radius: 10px;

  &.date {
    background-image: url(../../../../assets/images/dashboard/intervew-details/date.svg);
  }

  &.time {
    background-image: url(../../../../assets/images/dashboard/intervew-details/time.svg);
  }

  &.type {
    background-image: url(../../../../assets/images/dashboard/intervew-details/type.svg);
  }

  &.contact {
    background-image: url(../../../../assets/images/dashboard/intervew-details/contact.svg);
  }

  &.link {
    background-image: url(../../../../assets/images/dashboard/intervew-details/link.svg);
  }

  &.location {
    background-image: url(../../../../assets/images/dashboard/intervew-details/location.svg);
  }

  h4,
  .value {
    margin: 0;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: var(--black-color);
  }
}

@media (max-width: 1024px) {
  .InfoField {
    padding-right: 19px;

    &.link {
      .value {
        font-size: 16px;
        line-height: 18px;
      }
    }

    h4 {
      white-space: nowrap;
    }
  }
}
