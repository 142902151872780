.Message {
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(193, 197, 214, 0.3);

  &.my {
    .header {
      flex-direction: row-reverse;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h4 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: var(--black-color);
    }

    .createdAt {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--black-color);
    }
  }

  .message {
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--black-color);
  }
}

@media (max-width: 1024px) {
  .Message {
    padding-top: 20px;
    padding-bottom: 25px;

    .header {
      margin-bottom: 31px;
    }
  }
}
