@import '../../../mixins';

.PlayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  min-width: 124px;
  height: 124px;
  background-color: transparent;
  border: none;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover,
  &.animate {
    .button {
      background-color: var(--brand-color);
      transform: rotateY(180deg) scaleX(-1);

      .triangle {
        border-bottom: 46px solid var(--white-color);

        @include onMobile {
          border-bottom: 16px solid var(--white-color);
        }
      }

      .lines {
        .line {
          background-color: var(--white-color);
        }
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    min-width: 124px;
    height: 124px;
    background-color: white;
    border-radius: 50%;
    transform-style: preserve-3d;
    animation: flip 2s forwards;
    perspective: 1000px;
    transition: all 0.6s;

    @include onMobile {
      width: 46px;
      min-width: 46px;
      height: 46px;
    }

    span {
      display: inline-block;
    }

    .lines {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      @include onMobile {
        gap: 5px;
      }

      .line {
        width: 20px;
        height: 60px;
        background-color: var(--brand-color);
        animation: changeColors 2s forwards;

        @include onMobile {
          width: 7px;
          height: 24px;
        }
      }
    }

    .triangle {
      width: 0;
      height: 0;
      margin-left: 10%;
      border-left: 26.5px solid transparent;
      border-right: 26.5px solid transparent;
      border-bottom: 46px solid var(--brand-color);
      transform: rotate(90deg) scale(1.1547);
      animation: changeColors 2s forwards;

      @include onMobile {
        border-left: 9.5px solid transparent;
        border-right: 9.5px solid transparent;
        border-bottom: 16px solid var(--brand-color);
      }
    }
  }
}
