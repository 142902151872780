.Notification {
  position: relative;
  width: 100%;
  // height: 132px;
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 50px;
  padding-bottom: 22px;
  background-color: rgba(193, 197, 214, 0.1);
  border-bottom: 1.5px solid #d9d9d9;
  cursor: pointer;

  &.read {
    background-color: transparent;
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.24526 3.74998L6.51828 6.02284C6.68452 6.18907 6.68452 6.45858 6.51828 6.62481C6.35204 6.79104 6.08251 6.79104 5.91627 6.62481L3.64325 4.35194L1.37009 6.62494C1.20385 6.79117 0.934324 6.79117 0.768085 6.62494C0.601845 6.45872 0.601845 6.18921 0.768085 6.02298L3.04124 3.74998L0.767746 1.47664C0.601507 1.31041 0.601507 1.0409 0.767746 0.874671C0.933985 0.708443 1.20351 0.708443 1.36975 0.874671L3.64325 3.14801L5.91661 0.874807C6.08285 0.708579 6.35238 0.708579 6.51862 0.874807C6.68486 1.04104 6.68486 1.31054 6.51862 1.47677L4.24526 3.74998Z' fill='white'/%3E%3C/svg%3E%0A");
      background-color: var(--black-color);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .unreadMark,
  .deleteButton {
    position: absolute;
    top: calc(50% - 7.5px);
    right: 27px;
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--brand-color);
    border-radius: 50%;
  }

  .deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: var(--black-color);
    border: none;
  }

  .title {
    margin: 0;
    margin-bottom: 6px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: var(--black-color);
    white-space: pre-line;
  }

  .text {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    word-wrap: break-word;
    color: var(--black-color);

    .createdAt {
      color: var(--gray-color);
    }
  }

  .buttons {
    display: flex;
    gap: 16px;

    button {
      min-width: 112px;
      height: 30px;
      padding-left: 19px;
      padding-right: 19px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: var(--secondary-color);
      border: none;
      border-radius: 10px;

      &.button {
        background-color: var(--brand-color);
      }

      &.secondaryButton {
        background-color: var(--gray-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .Notification {
    padding-left: 16px;
    padding-right: 28px;
    &::after {
      right: 16px;
      width: 10px;
      height: 10px;
    }

    .title {
      font-size: 18px;
    }

    .text {
      font-size: 16px;
    }
  }
}
