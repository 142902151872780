.VideoShortWindow {
  position: absolute;
  z-index: 1;
  margin-top: 128px;
  width: 314px;
  border-radius: 20px;
  box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
  border: none;
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    //  justify-content: space-around;
    height: 427px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--white-color);
    border-radius: 20px;

    img {
      height: 70px;
      width: 70px;
      margin-bottom: 30px;
    }

    h1,
    p {
      margin: 0;
    }
    h1 {
      margin-bottom: 10px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 54px;
      line-height: 43px;
      text-align: center;
      color: var(--brand-color);
    }

    .subtitle {
      margin-bottom: 20px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      text-align: center;
      color: var(--black-color);
    }

    .mainText {
      width: 269px;
      margin-top: 40px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-top: auto;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;

    button {
      width: 128px;
      height: 54px;
      border-radius: 20px;
      border: none;
      cursor: pointer;

      &.reviewButton {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: var(--brand-color);
        background-color: var(--secondary-color);
      }

      &.nextButton {
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: var(--white-color);
        background-color: var(--black-color);
      }
    }
  }
}
