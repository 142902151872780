.calendarComponent {
  width: 378px;
  border: none;
  border-radius: 16px;

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    height: unset;
    margin-bottom: 25px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      display: inline-block;
      height: 44px;
      padding-left: 12px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 24px;
      font-family: 'Gilroy-SemiBold';
      font-size: 24px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #141414;
      white-space: nowrap;
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.71967 0.280329L0.28033 5.71967C-0.192143 6.19214 0.142482 7 0.81066 7H5.5C6.32843 7 7 6.32843 7 5.5V0.81066C7 0.142482 6.19214 -0.192145 5.71967 0.280329Z' fill='%23D52D34'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right 12px bottom 13px;
      background-color: #f2f4f8;
      border-radius: 6px;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__next-button,
    .react-calendar__navigation__prev-button {
      position: relative;
      top: -2px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: unset;
      width: 44px;
      height: 44px;
      padding: 0;
      font-size: 0;
      background-color: #f2f4f8;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.78001 10.0244C6.53139 11.4255 8.67586 13.5588 10.1172 16.4416L7.88111 17.5596C6.65576 15.1088 4.80024 13.2421 3.21828 11.9766C2.43174 11.3474 1.72502 10.8765 1.21904 10.5651C0.966409 10.4097 0.764853 10.2946 0.629698 10.2201C0.562151 10.1828 0.511288 10.1557 0.479032 10.1388L0.444881 10.121L0.440157 10.1186L0.439562 10.1183C0.43959 10.1183 0.439619 10.1184 0.440157 10.1173L0.44013 7.88382C0.439591 7.88281 0.439562 7.88282 0.439535 7.88284L0.44013 7.88254L0.444854 7.88012L0.479006 7.86239C0.511263 7.84547 0.562126 7.81837 0.629674 7.7811C0.764829 7.70653 0.966386 7.59147 1.21902 7.436C1.725 7.12462 2.43173 6.65372 3.21827 6.02447C4.80023 4.75889 6.65576 2.89218 7.88111 0.441406L10.1172 1.55941C8.67587 4.44214 6.5314 6.57551 4.78002 7.97663C4.28401 8.37345 3.81557 8.71468 3.39783 9.00055C3.81557 9.2864 4.284 9.62762 4.78001 10.0244Z' fill='black'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 80px;
    }

    .react-calendar__navigation__next-button {
      transform: scale(-1, -1);
    }
    .react-calendar__navigation__label {
      //  pointer-events: none;

      &:hover {
        background-color: transparent;
      }

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #333333;
      }
    }
  }

  .react-calendar__month-view {
    padding-left: 0;
    padding-right: 0;

    .react-calendar__month-view__weekdays {
      margin-bottom: 25px;
      .react-calendar__month-view__weekdays__weekday {
        height: unset;
        // margin-bottom: 2px;
        padding: 0;
        font-family: 'Gilroy-SemiBold';
        font-size: 18px;
        line-height: 100%;
        color: #1f1f1f;
        text-transform: none;

        abbr {
          text-decoration: none;
        }
      }
    }

    .react-calendar__month-view__days {
      gap: 4px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 50px !important;
        width: 50px;
        height: 44px;
        padding: 0;
        font-family: 'Gilroy-SemiBold';
        font-size: 18px;
        line-height: 100%;
        color: #1f1f1f;
        background-color: #f2f4f8;
        box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
        border-radius: 6px;

        // Past days
        &.react-calendar__tile {
          &:disabled {
            color: #c1c5d6;
          }
        }

        &.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
          color: var(--white-color);
          background-color: var(--black-color);
          border-radius: 6px;
        }

        &.react-calendar__tile--active,
        &.react-calendar__tile--hasActive {
          color: #00e4ac;
          background-color: #f2f4f8;
          border-radius: 0;
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          font-family: 'Gilroy-SemiBold';
          font-size: 18px;
          line-height: 100%;
          text-align: center;
          color: var(--gray-color);
          background-color: transparent;
          box-shadow: none;
        }

        &.react-calendar__tile--rangeStart {
          color: var(--white-color);
          text-shadow: 0px 1px 0px #000000;
          background-color: var(--brand-color);
          box-shadow: 0px 1.5px 1px rgba(0, 31, 112, 0.25),
            0px 3px 2px rgba(0, 71, 255, 0.2);
          border: none;
        }

        &.react-calendar__tile--rangeEnd {
          color: var(--white-color);
          text-shadow: 0px 1px 0px #000000;
          background-color: var(--brand-color);
          box-shadow: 0px 1.5px 1px rgba(0, 31, 112, 0.25),
            0px 3px 2px rgba(0, 71, 255, 0.2);
          border-radius: 6px;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .calendarComponent {
    width: 100%;

    .react-calendar__navigation {
      .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        font-size: 20px;
      }
    }

    .react-calendar__month-view__days {
      width: 100%;
      display: grid !important;
      grid-template-columns: repeat(7, minmax(0, 1fr));
      justify-items: center;
    }
  }
}
