.Star {
  width: 38px;
  height: 25px;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.08852 24.4425C5.40075 24.8041 4.59691 24.2201 4.72826 23.4542L6.04815 15.7587L0.45699 10.3086C-0.0994322 9.76624 0.207609 8.82127 0.976565 8.70953L8.70336 7.58676L12.1589 0.585095C12.5028 -0.111698 13.4964 -0.111698 13.8403 0.585095L17.2958 7.58676L25.0226 8.70953C25.7916 8.82127 26.0986 9.76624 25.5422 10.3086L19.951 15.7587L21.2709 23.4542C21.4023 24.2201 20.5984 24.8041 19.9106 24.4425L12.9996 20.8092L6.08852 24.4425Z' fill='white'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  background-repeat: no-repeat;

  &.active {
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.08837 24.4425C5.4006 24.8041 4.59675 24.2201 4.72811 23.4542L6.048 15.7587L0.456837 10.3086C-0.0995847 9.76624 0.207456 8.82127 0.976413 8.70953L8.70321 7.58676L12.1587 0.585095C12.5026 -0.111698 13.4962 -0.111698 13.8401 0.585095L17.2957 7.58676L25.0224 8.70953C25.7914 8.82127 26.0984 9.76624 25.542 10.3086L19.9509 15.7587L21.2708 23.4542C21.4021 24.2201 20.5983 24.8041 19.9105 24.4425L12.9994 20.8092L6.08837 24.4425Z' fill='%230ACF83'/%3E%3C/svg%3E%0A");

    &.red {
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.08837 24.4425C5.4006 24.8041 4.59675 24.2201 4.72811 23.4542L6.048 15.7587L0.456837 10.3086C-0.0995847 9.76624 0.207456 8.82127 0.976413 8.70953L8.70321 7.58676L12.1587 0.585095C12.5026 -0.111698 13.4962 -0.111698 13.8401 0.585095L17.2957 7.58676L25.0224 8.70953C25.7914 8.82127 26.0984 9.76624 25.542 10.3086L19.9509 15.7587L21.2708 23.4542C21.4021 24.2201 20.5983 24.8041 19.9105 24.4425L12.9994 20.8092L6.08837 24.4425Z' fill='%23FF2D55'/%3E%3C/svg%3E%0A");
    }
  }
}
