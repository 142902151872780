.Quiz {
  flex: 1;
  max-height: calc(100vh - 172px);
  padding-top: 66px;
  padding-left: 48px;
  padding-right: 25px;
  padding-bottom: 97px;

  .buttons {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    padding-top: 15px;
  }

  .container {
    height: 100%;
    padding-right: 40px;
    overflow-y: auto;

    .headerContainer {
      position: sticky;
      top: 0;
      background-color: var(--white-color);

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 51px;
        margin-bottom: 21px;
        padding-left: 12px;
        padding-right: 12px;
        border: 1px solid var(--brand-color);
        border-radius: 8px;

        h3 {
          margin: 0;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          color: #1e1e1e;
        }

        .questionsLeft {
          display: flex;
          gap: 1px;

          .title {
            display: flex;
            align-items: center;
            width: 59px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            text-align: right;
            color: #1e1e1e;
          }

          .count {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 65px;
            font-family: 'National 2 Condensed';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            text-align: right;
            color: #1e1e1e;
          }
        }
      }
    }

    .questions {
      display: flex;
      flex-direction: column;
      gap: 43px;
    }
  }
}

@media (max-width: 1024px) {
  .Quiz {
    max-height: unset;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;

    .buttons {
      flex-direction: column;
      gap: 20px;
      padding-right: 0;
      padding-top: 9px;
      padding-bottom: 29px;
    }

    .container {
      height: unset;
      padding-right: 0;
      .headerContainer {
        .header {
          height: 45px;
          margin-bottom: 25px;
          padding-left: 16px;
          padding-right: 7px;
          h3 {
            font-size: 18px;
            line-height: 22px;
            white-space: nowrap;
          }

          .questionsLeft {
            gap: 3px;
            .title {
              width: 44px;
              font-size: 14px;
              line-height: 15px;
            }

            .count {
              width: 44px;
              font-size: 22px;
              line-height: 24px;
            }
          }
        }
      }

      .questions {
        gap: 27px;
      }
    }
  }
}
