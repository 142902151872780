.VideoQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 443px;
  max-width: 443px;
  height: 322px;

  p {
    margin: 0;
    font-family: 'National 2 Condensed';
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: var(--black);

    &.recorded {
      margin-top: 13px;
      column-count: 2;
    }

    &.finish {
      margin-top: 24px;
      column-count: 2;
    }

    .columnEnd {
      &::after {
        content: '';
        display: block;
        height: 100%;
      }
    }

    .red {
      color: var(--brand-color);
    }
  }

  .question {
    max-width: 340px;
    margin-bottom: 40px;
  }
}
