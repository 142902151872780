.MobileMenu {
  display: flex;
  .toggle {
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .logo {
    display: none;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1001;

    img {
      width: 64px;
    }
  }

  .closeButton {
    position: absolute;
    z-index: 1001;
    top: 22px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .searchBarContainer {
    position: absolute;
    top: 29px;
    left: 20px;
    right: 16px;
  }

  .menusContainer {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-top: 29px;
    padding-left: 45px;
    padding-right: 20px;
    padding-bottom: 40px;
    background-color: var(--white-color);

    button {
      padding: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      background-color: transparent;
      border: none;

      &.signIn {
        color: var(--brand-color);
        text-decoration: underline;
      }
    }

    .secondaryMenu {
      display: flex;
      flex-direction: column;
      gap: 25px;
      list-style: none;
      margin: 0;
      padding: 0;

      .languagesList {
        padding: 0;
        display: flex;
        gap: 20px;
        font-family: 'National 2 Condensed';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        list-style: none;

        .lang {
          cursor: pointer;

          &.selectedLanguage {
            color: #d52d34;
            text-decoration-line: underline;
          }
        }
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin: 0;
      margin-bottom: 25px;
      padding: 0;
      list-style: none;

      a {
        text-decoration: none;
        span {
          font-family: 'National 2 Condensed';
          font-size: 28px;
          font-weight: 500;
          line-height: 40px;
          text-align: left;
          color: black;
          text-transform: uppercase;
        }
      }
    }
  }
}
