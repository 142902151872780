.backdrop {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 40, 51, 0.3);
}

.JobApplicationInfo {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 4;
  top: 0;
  right: -100%;
  bottom: 0;
  width: 746px;
  padding-top: 40px;
  background-color: var(--white-color);
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
  overflow-y: auto;

  &.visible {
    right: 0;
  }

  .headerContainer {
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media (max-width: 1024px) {
  .backdrop {
    backdrop-filter: blur(2px);
  }

  .JobApplicationInfo {
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-top: 24px;
    border-radius: 20px 20px 0 0;

    &.visible {
      top: 127px;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .headerContainer {
      margin-bottom: 51px;
    }
  }
}
