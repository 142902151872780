.ResumeHeader {
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
  h3 {
    width: 123px;
    margin: 0;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--black-color);
  }

  .infoIcon {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../../../../assets/images/info-icon.svg);
    font-size: 0;
  }
}

@media (max-width: 1024px) {
  .ResumeHeader {
    margin-bottom: 36px;
    h3 {
      width: 122px;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
