.Switch {
  .label {
    margin-bottom: 10px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #3c3c43;
  }

  .buttons {
    display: flex;
    gap: 40px;
    button {
      display: flex;
      width: 63px;
      height: 25px;
      padding-left: 35px;
      background-repeat: no-repeat;
      background-image: url(../../../../../../assets/images/dashboard/switch/thumb-up.svg);
      background-color: transparent;
      border: none;

      &.no {
        background-image: url(../../../../../../assets/images/dashboard/switch/thumb-down.svg);

        &:hover,
        &.active {
          background-image: url(../../../../../../assets/images/dashboard/switch/thumb-down-active.svg);
        }
      }

      &:hover,
      &.active {
        background-image: url(../../../../../../assets/images/dashboard/switch/thumb-up-active.svg);
        span {
          color: black;
        }
      }

      span {
        position: relative;
        bottom: 3px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 42px;
        color: var(--gray-color);
      }
    }
  }
}
