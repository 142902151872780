@import '../../mixins';

.VideoPlayer {
  position: relative;

  &.modal {
    video {
      width: 100%;
      height: 707px;
    }

    .playButtonContainer {
      &::after {
        left: 12px;
        top: 10px;
        width: 35px;
        height: 35px;
        background-color: black;
      }
    }
  }

  &:hover {
    .playButtonContainer {
      display: block;

      @include onMobile {
        display: none;
      }
    }
  }

  &:not(.playing) {
    .playButtonContainer {
      display: block;
    }
  }

  video {
    width: 414px;
    height: 736px;
    object-fit: cover;
    box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  .playButtonContainer {
    position: absolute;
    top: 40px;
    right: -38px;
    display: none;

    &.leftButton {
      right: unset;
      left: -38px;
    }

    &.centerButton {
      top: 50%;
      right: unset;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 22px;
      content: '';
      width: 40px;
      height: 40px;
      background-color: var(--white-color);
    }

    .playButton {
      position: relative;
      z-index: 1;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      img {
        width: 76px;
        height: 76px;
      }
    }
  }

  .text {
    position: absolute;
    bottom: 74px;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--white-color);
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: 30px;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;

    .time {
      display: inline-flex;
      min-width: 20px;
      max-width: 20px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--white-color);
    }

    .timeline {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 15px;

      .handle {
        position: absolute;
        left: 0;
        top: calc(50% - 13px);
        transform: translateX(-50%);
        z-index: 1;
        width: 26px;
        height: 26px;
        border-radius: 50%;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          content: '';
          width: 7px;
          height: 7px;
          background-color: var(--black-color);
          border-radius: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .progressBar {
        position: relative;
        width: 100%;
        height: 3px;
        background-color: var(--white-color);
        border-radius: 2px;
        overflow: hidden;

        .progress {
          width: 0;
          height: 100%;
          background-color: var(--black-color);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .VideoPlayer {
    &.preview {
      video {
        height: 886px;
      }
    }

    &.jobPage {
      video {
        height: calc(100dvh - 45px - 40px - 15px - 15px - 90px);
      }
    }

    video {
      width: 100%;
      height: calc(100svh - 40px);
    }

    .playButtonContainer {
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 76px;
      height: 76px;

      &.leftButton {
        left: 50%;
      }
    }
  }
}
