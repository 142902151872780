@import '../../../mixins';

.WordAbout {
  margin-top: -60px;

  .header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 167px;
    background-color: var(--brand-color);
    border-radius: 50px;

    @include onMobile {
      height: 100px;
      border-radius: 23px;
    }

    .title {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-size: 80px;
      font-weight: 700;
      line-height: 91.36px;
      text-align: center;
      text-transform: uppercase;
      color: var(--black-color);

      @include onMobile {
        font-size: 32px;
        line-height: 36px;
      }

      .white {
        color: var(--white-color);
      }
    }
  }

  .videoContainer {
    position: relative;
    // height: 824px;
    // max-height: 100vh;
    width: 100%;
    aspect-ratio: 16 / 9;
    max-height: 100vh;
    margin-top: -45px;

    @include onMobile {
      height: 316px;
      margin-top: -20px;
      aspect-ratio: unset;
      max-height: unset;
    }

    &:hover {
      .buttonContainer {
        opacity: 1;
      }
    }

    .buttonContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s;

      @include onMobile {
        opacity: 1;
      }
    }

    .imageCover {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
