@import '../../../mixins';

.Future {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  padding-top: 150px;
  padding-left: 88px;
  padding-right: 88px;
  padding-bottom: 177px;
  background-color: var(--secondary-color);
  scroll-margin-top: -90px;

  @include onMobile {
    padding-top: 150px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 60px;
  }

  .title {
    margin-bottom: 488px;
    font-family: 'National 2 Condensed';
    font-size: 80px;
    font-weight: 700;
    line-height: 91px;
    text-align: center;
    color: var(--brand-color);
    text-transform: uppercase;

    @include onMobile {
      margin-bottom: 187px;
      font-size: 30px;
      line-height: 34px;
    }

    .animated {
      display: inline-block;
      transform: translateY(120%);
      opacity: 0;

      &.inView {
        animation: slideUp 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 1243px;

    @include onMobile {
      max-width: 373px;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(120%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
