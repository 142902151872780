.FeedbackForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-top: 139px;
  padding-left: 47px;
  padding-right: 70px;

  &.feedbackSent {
    padding-top: 225px;
  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 16px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 50px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 629px;
    margin-bottom: 40px;
    padding: 22px;
    background-color: #f2f4f8;
    border-radius: 20px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .FeedbackForm {
    padding-top: 104px;
    padding-left: 0;
    padding-right: 0;

    &.feedbackSent {
      padding-top: 113px;
    }

    .container {
      width: 100%;
    }

    .buttons {
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 27px;
    }
  }
}
