.MessageWithButton {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-top: 236px;
  padding-bottom: 48px;

  .backIcon {
    position: absolute;
    top: 90px;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url(../../../../assets/images/dashboard/back-arrow.svg);
    cursor: pointer;
  }

  &.offer {
    padding-top: 0;
  }

  p {
    width: 583px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    color: var(--black-color);
  }

  .buttonsContainer {
    width: 100%;
    .offerButtonContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 19px;
      .offerButton {
        width: 288px;
        height: 54px;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: var(--white-color);
        background-color: var(--gray-color);
        border-radius: 10px;
        border: none;
      }
    }

    .buttons {
      position: relative;
      display: flex;
      justify-content: center;
      gap: 27px;

      .calendarButton {
        position: absolute;
        left: 0;
        top: -79px;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .MessageWithButton {
    width: 100%;
    padding-top: 174px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 61px;

    .backIcon {
      top: 80px;
      left: 15px;
    }

    p {
      width: unset;
      margin-bottom: 129px;
    }

    .buttonsContainer {
      .buttons {
        width: 100%;
        flex-direction: column;

        .calendarButton {
          left: 0;
          right: 0;
        }
      }

      .offerButtonContainer {
        margin-bottom: 30px;
        .offerButton {
          width: 100%;
        }
      }
    }
  }
}
