.TextInput {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.noShadow {
    input {
      border: 1px solid #f2f4f8;
      box-shadow: none;
    }
  }

  &.withType {
    input {
      padding-left: 49px;
      background-repeat: no-repeat;
      background-position: left 19px center;
    }
  }

  &.email {
    input {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10V4.24271C0 4.1312 0.117347 4.05867 0.217082 4.10854L6.65836 7.32918C7.50294 7.75147 8.49706 7.75147 9.34164 7.32918L15.7829 4.10854C15.8827 4.05867 16 4.1312 16 4.24271V10C16 11.1046 15.1046 12 14 12H2C0.895431 12 0 11.1046 0 10Z' fill='%23242833'/%3E%3Cpath d='M0 2V2.90729C0 2.96411 0.0321003 3.01605 0.082918 3.04146L6.65836 6.32918C7.50294 6.75147 8.49706 6.75147 9.34164 6.32918L15.9171 3.04146C15.9679 3.01605 16 2.96411 16 2.9073V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    }
  }

  &.phone {
    input {
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7071 10.7071L16.3552 13.3552C16.7113 13.7113 16.7113 14.2887 16.3552 14.6448C14.43 16.57 11.3821 16.7866 9.20399 15.153L7.62857 13.9714C5.88504 12.6638 4.33622 11.115 3.02857 9.37143L1.84701 7.79601C0.213407 5.61788 0.430014 2.56999 2.35523 0.644769C2.71133 0.288673 3.28867 0.288672 3.64477 0.644768L6.29289 3.29289C6.68342 3.68342 6.68342 4.31658 6.29289 4.70711L5.27175 5.72825C5.10946 5.89054 5.06923 6.13846 5.17187 6.34373C6.35853 8.71706 8.28294 10.6415 10.6563 11.8281C10.8615 11.9308 11.1095 11.8905 11.2717 11.7283L12.2929 10.7071C12.6834 10.3166 13.3166 10.3166 13.7071 10.7071Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    }
  }

  label {
    margin-bottom: 4px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: var(--black-color);
  }

  .error {
    position: absolute;
    bottom: -24px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ff2d55;
  }

  input {
    width: 100%;
    height: 54px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--black-color);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
      0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 10px;
    border: none;

    &::placeholder {
      color: var(--gray-color);
    }

    &:focus {
      outline: none;
    }
  }
}
