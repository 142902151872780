.BlackButton {
  width: 100%;
  height: 64px;
  font-family: 'National 2 Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--white-color);
  background-color: black;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 16px;
  border: none;
}
