.File {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 14px;
  padding-bottom: 18px;
  background-color: white;
  border-radius: 11px;

  img {
    height: 95px;
  }

  .name {
    width: 100%;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-family: National 2 Condensed;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #242833;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
