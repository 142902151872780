.listItem {
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  a {
    font-family: 'National 2 Condensed';
    font-size: 22px;
    font-weight: 500;
    line-height: 25.12px;
    text-align: left;
    text-decoration-line: none;
    text-transform: uppercase;
    color: var(--black-color);

    &.active {
      color: var(--brand-color);
    }
  }
}
