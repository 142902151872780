@import '../../../mixins';

.AreYouReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 85px;
  background-color: var(--secondary-color);

  @include onMobile {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
  }

  .title {
    margin-bottom: 81px;
    font-family: 'National 2 Condensed';
    font-size: 64px;
    font-weight: 700;
    line-height: 73px;
    text-align: center;
    color: var(--black-color);
    text-transform: uppercase;

    @include onMobile {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 34px;
    }

    .red {
      color: var(--brand-color);
    }
  }

  .button {
    width: 425px;
    height: 96px;
    font-family: 'National 2 Condensed';
    font-size: 50px;
    font-weight: 700;
    line-height: 96px;
    text-align: center;
    color: var(--white-color);
    text-transform: uppercase;
    background-color: var(--brand-color);
    border-radius: 56px;
    border: none;

    @include onMobile {
      width: 100%;
      max-width: 257px;
      height: 57px;
      font-size: 35px;
      line-height: 57px;
      border-radius: 24px;
    }
  }
}
