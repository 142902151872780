.VideoResume {
  width: 202px;

  .uploader {
    position: relative;
    height: 359px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23C1C5D6' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    .videoContainer {
      position: relative;
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 11px;
      }

      .videoDuration {
        position: absolute;
        z-index: 1;
        bottom: 33px;
        left: 50%;
        transform: translateX(-50%);
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: var(--gray-color);
      }
    }

    .addButton,
    .deleteButton {
      position: absolute;
      top: -13px;
      right: -13px;
      width: 30px;
      height: 30px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7681 5.02734V16.5145' stroke='white' stroke-width='1.64103' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.02441 10.7695H16.5116' stroke='white' stroke-width='1.64103' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-color: var(--black-color);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      border: none;
    }

    .deleteButton {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00025 3.99533L8.7878 0.207778C9.06483 -0.0692594 9.514 -0.0692595 9.79104 0.207778C10.0681 0.484816 10.0681 0.933983 9.79104 1.21102L6.00349 4.99857L9.79333 8.78841C10.0704 9.06545 10.0704 9.51461 9.79333 9.79165C9.51629 10.0687 9.06713 10.0687 8.79009 9.79165L5.00025 6.00181L1.21102 9.79104C0.933983 10.0681 0.484816 10.0681 0.207778 9.79104C-0.0692595 9.514 -0.0692594 9.06483 0.207778 8.7878L3.99701 4.99857L0.210073 1.21163C-0.0669649 0.934597 -0.0669649 0.48543 0.210073 0.208392C0.487111 -0.0686454 0.936278 -0.0686453 1.21332 0.208392L5.00025 3.99533Z' fill='white'/%3E%3C/svg%3E%0A");
      background-color: #ff2d55;
    }

    p {
      width: 81px;
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      &.resumeName {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .gray {
        color: var(--gray-color);
      }

      .red {
        color: var(--brand-color);
      }
    }
  }
}

@media (max-width: 1024px) {
  .VideoResume {
    width: 100%;
    .videoContainer {
      min-width: 100%;
      video {
        width: 100%;
      }
    }
  }
}
