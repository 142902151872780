.MyDashboardPage {
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding-top: 56px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 20px;
  background-color: var(--secondary-color);

  .row {
    display: flex;
    gap: 20px;

    .col {
      display: flex;
      flex-direction: column;
      flex: 1;

      &.jobsContainer {
        h2 {
          margin-bottom: 10px;
          font-family: 'National 2 Condensed';
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 40px;
          color: var(--black-color);
        }

        .jobsControlsContainer {
          margin-bottom: 15px;
        }

        .jobs {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
          gap: 20px;
        }
        .noJobs {
          margin-top: 102px;
          margin-bottom: 102px;
          text-align: center;
          font-family: 'National 2 Condensed';
          font-size: 22px;
          color: var(--black-color);
        }
      }

      &.statsContainer {
        gap: 20px;
      }

      .userBlockContainer {
        margin-bottom: 32px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .MyDashboardPage {
    gap: 0;
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
    .row {
      flex-direction: column;

      &:last-child {
        .col {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .actionsAndJobAppliedStatusContainer {
        display: flex;
        gap: 20px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }

      .col {
        padding-left: 16px;
        padding-right: 16px;
        &.jobsContainer {
          padding-left: 0;
          padding-right: 0;
          h2 {
            padding-left: 16px;
          }

          .jobs {
            padding-left: 16px;
            padding-right: 16px;
          }
        }

        &.statsContainer {
          flex-direction: row;
          gap: 16px;
        }
      }
    }
  }
}
