.AssessmentCard {
  height: 80px;
  padding: 2px 27px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  flex-grow: 1;
  font-family: National 2 Condensed;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #c1c5d6;
  background: #fff;
  cursor: pointer;

  &.selected {
    border-color: #d52d34;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    &:first-child {
      justify-self: flex-start;
      align-items: flex-start;
    }

    .value {
      color: #c1c5d6;
      font-family: National 2 Condensed;
      font-size: 14px;
      font-weight: 400;
    }

    .status {
      width: 125px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #d52d34;
      border-radius: 8px;
      border: 1px solid #d52d34;

      &.expired {
        color: white;
        background-color: #c1c5d6;
        border-color: #c1c5d6;
      }

      &.completed {
        background-color: #00e4ac;
        border-color: #00e4ac;
        color: #242833;
      }

      &.resume {
        color: #242833;
        background-color: white;
        border-color: #242833;
      }
    }
  }
}

@media (max-width: 1024px) {
  .AssessmentCard {
    padding: 2px 18px;
    font-size: 18px;

    .col {
      .status {
        width: 88px;
      }
    }
  }
}
