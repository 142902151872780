.EmailForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  padding-top: 48px;
  padding-bottom: 81px;
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;

  .header {
    margin-bottom: 115px;
    h1 {
      margin: 0;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 46px;
      text-align: center;
      color: var(--black-color);
    }
  }

  form {
    // width: 444px;
    width: 100%;
    margin-bottom: 72px;
  }

  .info {
    width: 300px;
    margin: 0;
    margin-bottom: 130px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--black-color);
  }

  .continueButtonContainer {
    width: 100%;
    // margin-top: auto;
  }
}

@media (max-width: 1024px) {
  .EmailForm {
    width: 100%;
    padding-top: 63px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 53px;
    .header {
      margin-bottom: 88px;
      h1 {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        color: var(--black-color);
      }
    }

    form {
      width: 100%;
      margin-bottom: 82px;
    }

    .info {
      margin-bottom: 128px;
    }
  }
}
