.TimePeriodSelector {
  display: flex;
  justify-content: space-between;
  width: 101px;
  height: 36px;
  padding: 2px;
  background-color: #f2f4f8;
  border-radius: 9px;
  overflow: hidden;

  button {
    width: 48px;
    height: 32px;
    font-family: 'Gilroy-Bold';
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.08px;
    background-color: transparent;
    border: none;
    border-radius: 7px;

    &.active {
      background-color: var(--white-color);
      border: 0.5px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
        0px 3px 1px rgba(0, 0, 0, 0.04);
    }
  }
}
