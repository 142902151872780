.Invited {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 142px;
  padding-bottom: 78px;

  .mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .jobInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 578px;

      .jobTitle {
        margin-bottom: 6px;
        font-family: 'National 2 Condensed';
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: var(--black-color);
      }

      .jobLocation {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 30px;
        font-family: 'National 2 Condensed';
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: var(--black-color);
      }

      .text {
        font-family: 'National 2 Condensed';
        font-size: 28px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        white-space: pre-line;
      }
    }

    .info {
      width: 521px;
      margin-bottom: 30px;
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 50px;
      text-align: center;
      color: var(--black-color);
    }
  }

  .buttons {
    display: flex;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .Invited {
    padding-top: 174px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 61px;
    .mainContent {
      .info {
        width: unset;
      }

      .jobInfo {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .buttons {
      width: 100%;
      flex-direction: column;
    }
  }
}
