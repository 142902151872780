.FullScreenModal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 100dvh;
  overflow-y: auto;
  background-color: var(--secondary-color);

  .header {
    padding-left: 14px;
    padding-right: 14px;

    .navContainer {
      position: relative;
    }
  }

  .content {
    height: 100%;
  }
}
