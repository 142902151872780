@import '../../../../mixins';

.Employee {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 589px;

  @include onMobile {
    height: 203px;
  }

  .title {
    max-width: 293px;
    margin: 0;
    font-family: 'National 2 Condensed';
    font-size: 39px;
    font-weight: 700;
    line-height: 44.54px;
    text-align: center;
    color: var(--white-color);
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.42, 0, 0.58, 1);

    @include onMobile {
      max-width: 100px;
      font-size: 10px;
      line-height: 11px;
    }

    &.visible {
      opacity: 1;
    }
  }

  .resizer {
    visibility: hidden;

    @include onMobile {
      width: 86px;
    }
  }

  .arrow {
    position: absolute;
    bottom: 111px;

    @include onMobile {
      bottom: 52px;
      width: 86px;
    }
  }

  .imageBlock {
    position: absolute;
    top: 141px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onMobile {
      top: 46px;
    }

    .image {
      transform: scale(0);
      transform-origin: bottom center;
      transition: transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);

      @include onMobile {
        width: 36px;
        height: 72px;
      }

      &.visible {
        transform: scale(1);
      }
    }
  }

  .textContainer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .text {
      font-family: 'National 2 Condensed';
      font-size: 25px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      color: var(--black-color);

      @include onMobile {
        font-size: 12px;
        line-height: 13px;
      }
    }

    .subText {
      min-height: 28.5px;
      font-family: 'National 2 Condensed';
      font-size: 25px;
      font-weight: 700;
      line-height: 28.55px;
      text-align: center;
      color: var(--white-color);

      @include onMobile {
        font-size: 12px;
        line-height: 13px;
        min-height: 13px;
      }
    }
  }
}
