.dialog {
  max-width: 357px;
}

.modalContent {
  border-radius: 20px;
  border: none;
}

.VideoPlayerModal {
  .content {
    position: relative;
    height: 806px;
    padding-top: 16px;
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 21px;
    background-color: var(--secondary-color);
    border-radius: 20px;

    .header {
      display: flex;
      align-items: center;
      height: 52px;
      margin-bottom: 10px;
      h1 {
        margin: 0;
        font-family: 'National 2 Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        color: var(--black-color);
      }

      .closeIcon {
        position: absolute;
        top: 26px;
        right: 17px;
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-color: var(--gray-color);
        background-image: url(../../assets/images/close-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .dialog {
    margin-left: auto;
    margin-right: auto;
  }
}
